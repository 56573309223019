import Vue from 'vue'

const consultingRoomStore = {
  namespaced: true,
  state: {
    fetching: false,
    fetched: false,
    consultingRooms: [],
    consultingRoomMeta: null,
    doctorTimeSlot: []
  },
  getters: {
    allConsultingRooms (state) {
      return state.consultingRooms
    },
    allMeta (state) {
      return state.consultingRoomMeta
    },
    currentDoctorTimeSlot (state) {
      return state.doctorTimeSlot
    }
  },
  mutations: {
    setConsultingRooms (state, payload) {
      state.consultingRooms = payload
    },
    setMeta (state, payload) {
      state.consultingRoomMeta = payload
    },
    setCurrentDoctorTimeSlot (state, payload) {
      state.doctorTimeSlot = payload
    }
  },
  actions: {
    async getConsultingRoomsRequest ({ commit, state }, params) {
      state.fetched = false

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getConsultingRooms(params)
          .then((response) => {
            // console.log(response)

            const { data: results, ...meta } = response.data

            commit('setConsultingRooms', results)
            commit('setMeta', meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async fetchTimeSlotRequest ({ commit, state }, { id, params }) {
      state.fetching = true

      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getWorkTimeDoctor(id, params)
          .then((response) => {
            commit('setCurrentDoctorTimeSlot', response.data)
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    async getConsultingRoomsByDoctorID ({ commit, state }, { id, params }) {
      state.fetched = false

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getAllConsultingRoomsByDoctorID(id, params)
          .then((response) => {
            console.log(response)

            const { data: results, ...meta } = response.data

            commit('setConsultingRooms', results)
            commit('setMeta', meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async getAllConsultingRooms ({ commit, state }, { params }) {
      state.fetched = false

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getAllConsultingRooms(params)
          .then((response) => {
            console.log(response)

            const { data: results, ...meta } = response.data

            commit('setConsultingRooms', results)
            commit('setMeta', meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    }
  }
}

export default consultingRoomStore
