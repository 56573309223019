import { CHAT_CONVERSATION_TYPE } from '@/utils/constants'
import Vue from 'vue'

const chatConversation = {
  namespaced: true,
  state: {
    chatList: {
      group: {},
      direct: {}
    },
    params: {
      group: {},
      direct: {}
    },
    reloadData: false
  },
  getters: {
    getAllChatListConversation (state) {
      return state.chatList
    },
    getGroupChatListConversation (state) {
      return state.chatList?.group
    },
    getDirectChatListConversation (state) {
      return state.chatList?.direct
    },
    getParamsGroupChatListConversation (state) {
      return state.params?.group
    },
    getParamsDirectChatListConversation (state) {
      return state.params?.direct
    },
    getReloadDataChatListConversation (state) {
      return state.reloadData
    }
  },
  mutations: {
    SET_GROUP_CHAT_LIST (state, payload) {
      state.chatList.group = payload
    },
    SET_DIRECT_CHAT_LIST (state, payload) {
      state.chatList.direct = payload
    },
    SET_PARAMS_GROUP_CHAT_LIST (state, payload) {
      state.params.group = payload
    },
    SET_PARAMS_DIRECT_CHAT_LIST (state, payload) {
      state.params.direct = payload
    },
    SET_RELOAD_DATA_CHAT_LIST (state, payload) {
      state.reloadData = payload
    }
  },
  actions: {
    getChatListData (ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('AuthRequest')
          .getChatListV2(params)
          .then((response) => {
            if (!response?.data) return

            const data = response.data

            Vue.prototype.$conversationsList = {
              ...Vue.prototype.$conversationsList,
              loading: false
            }

            if (params?.type === CHAT_CONVERSATION_TYPE.GROUP) {
              ctx.commit('SET_GROUP_CHAT_LIST', data)
              ctx.commit('SET_PARAMS_GROUP_CHAT_LIST', params)
            } else if (params?.type === CHAT_CONVERSATION_TYPE.DIRECT) {
              ctx.commit('SET_DIRECT_CHAT_LIST', data)
              ctx.commit('SET_PARAMS_DIRECT_CHAT_LIST', params)
            }

            resolve(data)
          })
          .catch((error) => reject(error))
      })
    }
  },
  modules: {}
}

export default chatConversation
