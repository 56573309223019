<template>
  <div class="p-3">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <default-template :inputs="form" :checkboxs="checkboxs" :case_item="case_item"></default-template>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'DefaultPreviewTemplate',
    props: {
      form: Object,
      case_item: Object
    },
    data () {
      return {
        checkboxs: {
          medical_condition_ids: [],
          condition_is_ids: [],
          doctor_recommend_ids: []
        }
      }
    },
    methods: {
      onSubmit () {

      }
    }
  }
</script>
