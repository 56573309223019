import { APPOINTMENT_STATUS, PATIENT_RECEPTION_TYPE } from '../utils/constants'

import _ from 'lodash'
import Vue from 'vue'

const appointmentStore = {
  namespaced: true,
  state: {
    fetching: false,
    fetched: false,
    searchData: [],
    searchMeta: null,
    appointments: [],
    appointmentMeta: null,
    appointmentDetail: null,
    waitingExaminations: [],
    waitingExaminationMeta: null,
    waitingExaminationDetail: null,
    listAudio: []
  },
  getters: {
    searchAllData (state) {
      return state.searchData
    },
    searchAllMeta (state) {
      return state.searchMeta
    },
    allAppointments (state) {
      return state.appointments
    },
    allAppointmentMeta (state) {
      return state.appointmentMeta
    },
    appointmentById (state) {
      return state.appointmentDetail
    },
    allWaitingExaminations (state) {
      return state.waitingExaminations
    },
    allWaitingExaminationMeta (state) {
      return state.waitingExaminationMeta
    },
    waitingExaminationById (state) {
      return state.waitingExaminationDetail
    },
    allAudio (state) {
      return state.listAudio
    }
  },
  mutations: {
    setSearchAllData (state, payload) {
      state.searchData = payload
    },
    setSearchAllMeta (state, payload) {
      state.searchMeta = payload
    },
    setAppointments (state, payload) {
      state.appointments = payload
    },
    setAppointmentMeta (state, payload) {
      state.appointmentMeta = payload
    },
    setAppointmentById (state, payload) {
      state.appointmentDetail = payload
    },
    setWaitingExaminations (state, payload) {
      state.waitingExaminations = payload
    },
    setWaitingExaminationMeta (state, payload) {
      state.waitingExaminationMeta = payload
    },
    setWaitingExaminationById (state, payload) {
      state.waitingExaminationDetail = payload
    },
    setListAudio (state, payload) {
      state.listAudio = payload
    }
  },
  actions: {
    async getSearchAllRequest ({ commit, state }, { params, refetch = true }) {
      state.fetched = !refetch

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        const newParams = {
          ...params
        }

        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getPatientsReception(newParams)
          .then((response) => {
            const { data: dataRaw, ...meta } = response.data

            const results = dataRaw?.map((item) => {
              const data = _.sortBy(item?.appts, (el) => el.start_time).filter(
                (item) =>
                  item.clinic_id === params.clinic_id &&
                  Vue.prototype
                    .moment(item?.start_time)
                    .startOf('day')
                    .toDate() -
                    Vue.prototype.moment().startOf('day').toDate() >=
                    0 &&
                  (item.status === APPOINTMENT_STATUS.CODE.CLINIC_PENDING ||
                    item.status === APPOINTMENT_STATUS.CODE.PATIENT_ACCEPTED)
              )
              return {
                ...item,
                appts: data
              }
            })

            commit('setSearchAllData', results)
            commit('setSearchAllMeta', meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async getAppointmentRequest ({ commit, state }, { params, refetch = true }) {
      state.fetched = !refetch

      if (state.fetched) {
        return
      }

      state.fetching = true
      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .allApptsV3(params)
          .then((response) => {
            // console.log(response.data)

            commit('setAppointments', response.data)
            commit('setAppointmentMeta', response.meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async getAppointmentRequestV2 (
      { commit, state },
      { params, refetch = true }
    ) {
      state.fetched = !refetch

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        const newParams = {
          ...params,
          examination_status: 0
        }

        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .allApptsV2(newParams)
          .then((response) => {
            // console.log(response.data)

            commit('setAppointments', response.data)
            commit('setAppointmentMeta', response.meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async getAppointmentByIdRequest ({ commit, state }, { id, type }) {
      if (!Number(id)) return
      try {
        if (type === PATIENT_RECEPTION_TYPE.CODE.WAITING_EXAMINATION) {
          await Vue.prototype.$rf
            .getRequest('DoctorRequest')
            .getDetailPatientVisit(id)
            .then((response) => {
              const data = response.data
              const newData = {
                ...data,
                price: data?.appt?.price,
                invoice: data?.appt?.invoice,
                doctor_name: data.doctor?.name,
                person_name: data.person?.name,
                appt_reason: data.visit_reason
              }
              commit('setAppointmentById', {
                ...state.appointmentDetail,
                patientVisit: newData
              })
              state.fetched = true
            })
          return
        }

        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getApptsV2(id)
          .then((response) => {
            commit('setAppointmentById', {
              appt: response.data
            })
            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async getWaitingExaminationRequest (
      { commit, state },
      { params, refetch = false }
    ) {
      state.fetched = !refetch
      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        const newParams = {
          ...params,
          keyword: params.search || ''
          // status: EXAMINATION_STATUS.CODE.WAITING
        }
        const res = await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getPatientVisitsV2(newParams)
          .then((response) => {
            // console.log(response.data)

            const { data: results, ...meta } = response.data

            commit('setWaitingExaminations', results)
            commit('setWaitingExaminationMeta', meta)

            state.fetched = true
            return response.data
          })
        return res
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    },
    async checkInUser ({ commit, state }, { id, data, apptData }) {
      if (!id || !data) return

      state.fetching = true
      try {
        let res = null
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .setPatientVisits(data)
          .then((response) => {
            let newData = {
              ...state.appointmentDetail,
              appt: {
                ...state.appointmentDetail?.appt
              },
              patientVisit: {
                ...state.appointmentDetail?.patientVisit,
                ...response.data
              }
            }

            if (apptData) {
              newData = {
                ...newData,
                appt: {
                  ...newData.appt,
                  ...apptData
                }
              }
            }

            newData = {
              ...newData,
              appt: {
                ...newData.appt,
                examination_status: response.data?.status
              }
            }

            commit('setAppointmentById', newData)
            res = newData
          })

        return res
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    async updateStatusApptRequest ({ commit, state }, { id, data }) {
      if (!id || !data) return

      state.fetching = true

      try {
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .ApptStatus(id, data)
          .then((response) => {
            const newData = {
              ...state.appointmentDetail,
              ...response.data
            }
            commit('setAppointmentById', newData)
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    async updateApptByIdRequest ({ commit, state }, { id, data }) {
      if (!id || !data) return

      try {
        state.fetching = true

        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .updateAppts(id, data)
          .then((response) => {
            const newData = {
              ...state.appointmentDetail,
              appt: {
                ...state.appointmentDetail.appt,
                ...response.data
              }
            }
            commit('setAppointmentById', newData)
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    async updatePatientVisitByIdRequest (
      { commit, state },
      { id, data, isDetail = true }
    ) {
      if (!id || !data) return

      try {
        state.fetching = true
        let res = null
        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .updatePatientVisitsById(id, data)
          .then((response) => {
            if (isDetail) {
              const newData = {
                ...state.appointmentDetail,
                patientVisit: {
                  ...state.appointmentDetail.patientVisit,
                  ...response.data
                }
              }
              commit('setAppointmentById', newData)
            }

            res = response.data
          })
        return res
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    async callPatientRequest ({ commit, state }, { id, data }) {
      if (!id) return

      try {
        state.fetching = true
        const response = await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .callPatient(id, data)

        return response.data
      } catch (error) {
        console.log(error)
        state.fetching = false
      } finally {
        state.fetching = false
      }
    },
    addAudioToList ({ commit, state }, data) {
      if (!data) return
      try {
        const listData = [...state.listAudio, data]
        commit('setListAudio', listData)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default appointmentStore
