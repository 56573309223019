const basicTwo = {
  namespaced: true,
  state: {
    form: {
      title: 'BỆNH VIỆN BƯU ĐIỆN',
      ms: '01/BV-01',
      so_luu_tru: '21NTD/0003',
      ma_y_te: '21NTD/0003',
      dan_toc: 'Kinh',
      nghe_nghiep: '',
      bhyt: '',
      vao_vien: '',
      ra_vien: '',
      phuong_phap_dieu_tri: '',
      padding: {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10
      }
    }
  },
  mutations: {
    setForm (state, {form}) {
      state.form = form
    }
  }
}

export default basicTwo
