import e3kit from '@/libs/e3kit'
import Vue from 'vue'

const app = {
  namespaced: true,
  state: {
    error_message: '',
    success_message: '',
    user: null,
    e3kit: null,
    e3kitToken: null
  },
  mutations: {
    setErrorMessage (state, { message }) {
      state.error_message = message
    },
    setSuccessMessage (state, { message }) {
      state.success_message = message
    },
    setUser (state, { user }) {
      state.user = user
    },
    setE3Kit (state, payload) {
      state.e3kit = payload
    },
    setE3KitToken (state, payload) {
      state.e3kitToken = payload
    }
  },
  actions: {
    setUser (context, { user }) {
      context.commit('setUser', { user })
    },
    showMessage (context, { message }) {
      // Set global message.
      context.commit('setAlertMessage', { message })
      // Show alert.
      window.jQuery('#toast__app').toast('show')
    },
    showErrorModal (context, { message }) {
      context.commit('setErrorMessage', { message })
      context.commit('setSuccessMessage', { message: '' })

      window.jQuery('#modal__app').modal('show')
    },
    showSuccessModal (context, { message }) {
      context.commit('setErrorMessage', { message: '' })
      context.commit('setSuccessMessage', { message })

      window.jQuery('#modal__app').modal('show')
    },
    async initializeE3Kit (ctx, payload) {
      if (ctx.state.e3kitToken && ctx.state.e3kit !== null) {
        return
      }

      try {
        const getToken = async () => {
          const res = await Vue.prototype.$rf
            .getRequest('AuthRequest')
            .getVirgilGenerationToken()
          const token = res?.data?.token

          if (!token) return
          ctx.commit('setE3KitToken', token)

          return token
        }

        const EThree = window.E3kit.EThree
        const eThree = await EThree.initialize(getToken)

        Vue.prototype.$e3kit = eThree
        window.$e3kit = eThree
        ctx.commit('setE3Kit', eThree)
        ctx.dispatch('clearE3KitUser')

        let repetitions = 0
        ctx.dispatch('runInitE3kit', {
          repetitions: repetitions++
        })
        return eThree
      } catch (error) {
        console.log(error)
      }
    },
    async clearE3KitUser (ctx, payload) {
      await e3kit.cleanup()
      // await e3kit.unregister();
      // await e3kit.resetPrivateKeyBackup()
    },
    async runInitE3kit (ctx, { repetitions }) {
      repetitions++

      try {
        const hasLocalPrivateKey = await e3kit.hasLocalPrivateKey()
        if (!hasLocalPrivateKey) {
          await e3kit.register()
          await Vue.prototype.$rf
            .getRequest('DoctorRequest')
            .registerEncryption()
        }
        if (repetitions <= 1) {
          await ctx.dispatch('runInitE3kit', { repetitions })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async refreshE3kitToken (ctx, payload) {
      try {
        const getToken = async () => {
          const res = await Vue.prototype.$rf
            .getRequest('AuthRequest')
            .getVirgilGenerationToken()
          const token = res?.data?.token

          if (!token) return
          ctx.commit('setE3KitToken', token)

          return token
        }

        const EThree = window.E3kit.EThree
        const eThree = await EThree.initialize(getToken)

        Vue.prototype.$e3kit = eThree
        window.$e3kit = eThree
        ctx.commit('setE3Kit', eThree)
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default app
