var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-temp',{attrs:{"show":_vm.show,"id":"indicationUltrasoundTemp","title":_vm.$t('Phiếu kết quả'),"code":_vm.formData.indication.code,"form-data":_vm.formData,"save_pdf":_vm.save_pdf,"check-lang":_vm.checkLang}},[(_vm.form)?_c('div',{staticClass:"mb-8"},[(
        _vm.form.type === 1 && _vm.tableData.length && Object.keys(_vm.metaData).length
      )?[_c('div',{staticClass:"block mb-2"},[_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Người lấy mẫu"))+":")]),(_vm.metaData.collection_by_employee_name)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.metaData.collection_by_employee_name)}}):_vm._e()]),_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Thời gian lấy mẫu"))+":")]),(_vm.metaData.collection_date_time)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(
              _vm.metaData &&
              _vm.appUtils.formatDateTime(_vm.metaData.collection_date_time)
            )}}):_vm._e()])]),_c('div',{staticClass:"block mb-2"},[_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Người hoàn thành"))+":")]),(_vm.metaData.completed_by_full_name)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.metaData.completed_by_full_name)}}):_vm._e()]),_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Thời gian hoàn thành"))+":")]),(_vm.metaData.completed_date_time)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(
              _vm.metaData &&
              _vm.appUtils.formatDateTime(_vm.metaData.completed_date_time)
            )}}):_vm._e()])])]:(_vm.form.type === 2 && Object.keys(_vm.metaData).length)?[_c('div',{staticClass:"block mb-2"},[_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Kỹ thuật viên 1"))+":")]),(_vm.metaData.first_tech_employee_name)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.metaData.first_tech_employee_name)}}):_vm._e()]),_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Kỹ thuật viên 2"))+":")]),(_vm.metaData.second_tech_employee_name)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.metaData.second_tech_employee_name)}}):_vm._e()])]),_c('div',{staticClass:"block mb-2"},[_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Bác sĩ đọc kết quả"))+":")]),(_vm.metaData.summary_employee_name)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.metaData.summary_employee_name)}}):_vm._e()]),_c('div',{staticClass:"w-half inline-block"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Ngày đọc kết quả"))+":")]),(_vm.metaData.completed_date_time)?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(
              _vm.metaData &&
              _vm.appUtils.formatDateTime(_vm.metaData.completed_date_time)
            )}}):_vm._e()])])]:_vm._e(),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Mô tả"))+":")]),(
          _vm.form.indication.examination_results &&
          _vm.form.indication.examination_results.conclusion
        )?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",class:_vm.isHTML ? 'border-b-cs' : '',domProps:{"innerHTML":_vm._s(_vm.form.indication.examination_results.conclusion.description)}}):_vm._e()]),(
        _vm.form.type === 1 && _vm.tableData.length && Object.keys(_vm.metaData).length
      )?_c('div',{staticClass:"mb-2 hodo-table"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Kết quả xét nghiệm"))+":")]),_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Xét nghiệm")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Kết quả")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Đơn vị")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Bình thường")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Máy XN")])]),_c('th',{staticClass:"text-center"},[_c('span',[_vm._v("Ghi chú")])])])]),_c('tbody',_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:`result-${index}`},[(row.is_full_row)?[_c('td',{attrs:{"colspan":6}},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(row.test_name))])])]:[_c('td',{attrs:{"colspan":1}},[_c('span',[_vm._v(_vm._s(row.test_name))])]),_c('td',[[_c('span',{staticClass:"block text-center",class:{ 'font-bold': row.is_alert }},[_c('span',{style:(row.low_high === 'H'
                          ? 'color: red !important'
                          : row.low_high === 'L'
                          ? 'color: blue !important'
                          : '')},[_vm._v(_vm._s(row.result))]),(row.low_high !== '')?_c('span',{staticClass:"ml-1",style:(row.low_high === 'H'
                          ? 'color: red !important'
                          : row.low_high === 'L'
                          ? 'color: blue !important'
                          : '')},[_vm._v(_vm._s(row.low_high ? `(${row.low_high})` : ""))]):_vm._e()])]],2),_c('td',[_c('span',{staticClass:"block text-center"},[_vm._v(_vm._s(row.unit))])]),_c('td',[_c('span',{staticClass:"block text-center"},[_vm._v(_vm._s(row.normal_rate))])]),_c('td',[_c('span',{staticClass:"block text-center"},[_vm._v(_vm._s(row.device))])]),_c('td',[_c('span',[_vm._v(_vm._s(row.note))])])]],2)}),0)])]):(_vm.form.type === 2 && Object.keys(_vm.metaData).length)?_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-bold fs-18"},[_vm._v(_vm._s(_vm.$t("Kết quả chẩn đoán"))+":")]),(
          _vm.form.indication.examination_results &&
          _vm.form.indication.examination_results.description
        )?_c('div',{staticClass:"hodo-table overflow-hidden fs-18 content-size",domProps:{"innerHTML":_vm._s(_vm.form.indication.examination_results.description)}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-bold mb-2 fs-18"},[_vm._v(_vm._s(_vm.$t(_vm.isXQuang ? "Lời dặn của BS chuyên khoa" : "Kết luận"))+":")]),(
          _vm.form.indication.examination_results &&
          _vm.form.indication.examination_results.conclusion
        )?_c('div',{staticClass:"hodo-table fs-18 content-size",domProps:{"innerHTML":_vm._s(_vm.form.indication.examination_results.conclusion.conclusion)}}):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"font-bold mb-2 fs-18"},[_vm._v("Nhận định kết quả:")]),(
          _vm.form.indication.examination_results &&
          _vm.form.indication.examination_results.conclusion
        )?_c('div',{staticClass:"hodo-table fs-18 content-size",domProps:{"innerHTML":_vm._s(_vm.form.indication.examination_results.conclusion.indentify)}}):_vm._e()])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }