const basic = {
  namespaced: true,
  state: {
    form: {
      padding: {
        top: 60,
        left: 70,
        right: 70,
        bottom: 0
      }
    }
  },
  mutations: {
    setForm (state, {form}) {
      state.form = form
    }
  }
}

export default basic
