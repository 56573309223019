<template>
  <div ref="documentPrint" id="print" v-show="false">
    <div id="bienbantemp" class="relative hodo-print">
      <div class="block fs-14" v-if="saveLang === 1">
        <div class="row mb-3">
          <div class="col-3 uppercase">
            <div v-if="clinic" class="inline-block align-top uppercase">
              <div class="flex flex-col" style="width: 300px">
                <p
                  class="mb-0 fs-17 font-bold"
                  v-if="clinic.organization && clinic.organization.authority_in_charge"
                >{{ clinic.organization.authority_in_charge }}</p>
                <p
                  class="mb-0 fs-17 font-bold"
                  v-if="clinic.organization && clinic.organization.name"
                >{{ clinic.organization && clinic.organization.name }}</p>
                <p
                  class="mb-0 fs-14 font-bold"
                  :class="!clinic.organization&& 'mt-12'"
                  v-if="clinic.name"
                >{{ clinic.name || "" }}</p>
              </div>
            </div>
          </div>
          <div class="col-6 text-center">
            <p class="fs-17 font-bold mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
            <p class="fs-17 font-bold mb-0">Độc lập - Tự do - Hạnh phúc</p>
            <p class="fs-17 font-bold mb-0">------------------------------</p>
          </div>
          <div class="col-3 d-flex justify-content-end text-right">
            <div class="whitespace-nowrap inline-block text-left">
              <div class="fs-17">MS: {{ '42/BV-99' || '................................' }}</div>
              <!-- <div
                  class="fs-17"
              >Mã BN: {{ mdt_disease && mdt_disease.medical_record && mdt_disease.medical_record.person_ref_id || '..........................' }}</div>-->
              <!-- <div class="fs-17">Mã BN: {{ '' || '..........................' }}</div> -->
              <div
                class="fs-17"
              >Số HS: {{ form.profile_code || (mdt_disease && mdt_disease.medical_record && mdt_disease.medical_record.ref_id) || '...................' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-8">
        <p
          class="fs-24 text-center uppercase font-bold mb-2"
        >{{ $t("multidisciplinary_board.lbl_mdt_report_title", getLangCode) }}</p>
      </div>
      <div>
        <div class>
          <div class="mb-6">
            <div class="block mb-2">
              <div class="w-half inline-block">
                <div>
                  <span class="mr-1 fs-18">
                    {{
                    $t("multidisciplinary_board.lbl_patient_name", getLangCode)
                    }}:
                  </span>
                  <span
                    class="font-bold uppercase fs-18"
                  >{{ mdt_disease && mdt_disease.patient_name }}</span>
                </div>
              </div>
              <div class="w-half inline-block">
                <div class="w-half inline-block">
                  <div v-if="!aliasData">
                    <span class="capitalize fs-18">
                      {{
                      $t("multidisciplinary_board.lbl_age", getLangCode) }}:
                    </span>
                    <span class="font-bold fs-18">
                      {{
                      mdt_disease &&
                      mdt_disease.patient_birthday &&
                      mdtUtils.getAges(
                      new Date(mdt_disease.patient_birthday), getLangCode
                      )
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <span class="capitalize fs-18">
                      {{
                      $t("multidisciplinary_board.lbl_age", getLangCode) }}:
                    </span>
                    <span class="font-bold fs-18">
                      {{
                      mdt_disease && aliasData &&
                      mdtUtils.getAges(aliasData.alias_age)
                      }}
                    </span>
                  </div>
                </div>
                <div class="w-half inline-block">
                  <div v-if="!aliasData">
                    <span
                      class="mr-1 fs-18"
                    >{{ $t("multidisciplinary_board.lbl_genders", getLangCode) }}:</span>
                    <span class="font-bold fs-18">
                      {{
                      mdt_disease &&
                      mdt_disease.patient_gender &&
                      mdtUtils.getGender(mdt_disease.patient_gender, getLangCode)
                      }}
                    </span>
                  </div>
                  <div v-else>
                    <span
                      class="mr-1 fs-18"
                    >{{ $t("multidisciplinary_board.lbl_genders", getLangCode) }}:</span>
                    <span class="font-bold fs-18">
                      {{
                      mdt_disease && aliasData &&
                      aliasData.alias_gender &&
                      mdtUtils.getGender(aliasData.alias_gender, getLangCode)
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="block mb-2">
              <div class="row mx-0">
                <div class="col-6 px-0">
                  <span class="fs-18">
                    {{
                    $t(
                    "multidisciplinary_board.lbl_address",
                    getLangCode
                    )
                    }}:
                  </span>
                  <span class="fs-18">{{ form.address }}</span>
                </div>
                <div class="col-3 px-0">
                  <span class="mr-1 fs-18">
                    {{
                    $t(
                    "multidisciplinary_board.lbl_admission_room",
                    getLangCode
                    )
                    }}:
                  </span>
                  <span class="fs-18">{{ form && form.admission_room }}</span>
                </div>
                <div class="col-3 px-0">
                  <span class="mr-1 fs-18">
                    {{
                    $t(
                    "multidisciplinary_board.lbl_admission_bed",
                    getLangCode
                    )
                    }}:
                  </span>
                  <span class="fs-18">{{ form && form.admission_bed }}</span>
                </div>
              </div>
            </div>
            <div class="block mb-2">
              <div class="row mx-0">
                <div class="px-0 fs-18 whitespace-nowrap" :class="saveLang===1?'col-4':'col-6'">
                  <span>
                    {{
                    $t(
                    "multidisciplinary_board.lbl_treatment_period_from",
                    getLangCode
                    )
                    }}:
                  </span>
                  <span
                    class="fs-18"
                  >{{ form && form.treatment_time_start && getDateTime(form.treatment_time_start) }}</span>
                </div>

                <div class="px-0 fs-18 whitespace-nowrap" :class="saveLang===1?'col-4':'col-6'">
                  <span>
                    {{
                    $t(
                    "multidisciplinary_board.lbl_treatment_period_to",
                    getLangCode
                    )
                    }}:
                  </span>
                  <span
                    class="fs-18"
                  >{{ form && form.treatment_time_end && getDateTime(form.treatment_time_end) }}</span>
                </div>
                <div class="col-4 px-0 fs-18 whitespace-nowrap" v-if="saveLang===1">
                  <span
                    class="mr-1 fs-18"
                  >{{ $t("multidisciplinary_board.lbl_mdt_meeting_at", getLangCode) }}:</span>
                  <span class="fs-18">{{ form && form.mdt_time && getDateTime(form.mdt_time) }}</span>
                </div>
              </div>
            </div>
            <div v-if="saveLang!==1" class="block mb-2">
              <div class="px-0 fs-18 whitespace-nowrap">
                <span
                  class="mr-1 fs-18"
                >{{ $t("multidisciplinary_board.lbl_mdt_meeting_at", getLangCode) }}:</span>
                <span class="fs-18">{{ form && form.mdt_time && getDateTime(form.mdt_time) }}</span>
              </div>
            </div>
            <div class="block mb-2">
              <div class="w-half inline-block">
                <span
                  class="mr-1 fs-18"
                >{{ $t("multidisciplinary_board.lbl_mems_preside", getLangCode) }}:</span>
                <span class="fs-18">{{ form.preside }}</span>
              </div>
              <div class="w-half inline-block">
                <span
                  class="mr-1 fs-18"
                >{{ $t("multidisciplinary_board.lbl_mems_type_secretary", getLangCode) }}:</span>
                <span class="fs-18">{{ form.secretary }}</span>
              </div>
            </div>
            <div class="block mb-2">
              <div>
                <span
                  class="mr-1 fs-18"
                >{{ $t("multidisciplinary_board.lbl_participants", getLangCode) }}:</span>
                <span class="fs-18">{{ form.members }}</span>
              </div>
            </div>
            <div class="block mb-2">
              <div>
                <span
                  class="mr-1 fs-18"
                >{{ $t("multidisciplinary_board.lbl_diagnose", getLangCode) }}:</span>
                <span class="fs-18">{{ multiLanguageData.diagnostic }}</span>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="mb-2">
              <div
                class="font-bold mb-2 uppercase fs-18"
              >{{ $t("multidisciplinary_board.lbl_treatment_his", getLangCode) }}:</div>
              <div
                style="max-width: 100% !important; word-break: break-all !important;"
                class="fs-18"
                v-html="
                saveLang == 1
                  ? multiLanguageData.treatment_progress.vi
                  : saveLang == 2
                    ? multiLanguageData.treatment_progress.en
                    : saveLang == 3
                      ? multiLanguageData.treatment_progress.fr
                      : ''
              "
              ></div>
            </div>
            <div class="mb-2">
              <div
                class="font-bold mb-2 uppercase fs-18"
              >{{ $t("multidisciplinary_board.lbl_conclusion", getLangCode) }}:</div>
              <div
                style="max-width: 100% !important; word-break: break-all !important;"
                class="fs-18"
                v-html="
                saveLang == 1
                  ? multiLanguageData.conclusion.vi
                  : saveLang == 2
                    ? multiLanguageData.conclusion.en
                    : saveLang == 3
                      ? multiLanguageData.conclusion.fr
                      : ''
              "
              ></div>
            </div>
            <div class="mb-2">
              <div
                class="font-bold mb-2 uppercase fs-18"
              >{{ $t("multidisciplinary_board.lbl_dicision", getLangCode) }}:</div>
              <div
                style="max-width: 100% !important; word-break: break-all !important;"
                class="fs-18"
                v-html="
                saveLang == 1
                  ? multiLanguageData.treatment.vi
                  : saveLang == 2
                    ? multiLanguageData.treatment.en
                    : saveLang == 3
                      ? multiLanguageData.treatment.fr
                      : ''
              "
              ></div>
            </div>
          </div>
        </div>
        <div class="block w-full">
          <div class="w-half inline-block align-top">
            <div class="text-center mb-2">&nbsp;</div>
            <div class="text-center uppercase fs-17">
              {{
              $t(
              "multidisciplinary_board.lbl_mems_type_secretary",
              getLangCode
              )
              }}
            </div>
            <div class="text-center mt-12 fs-17">{{ form.secretary }}</div>
          </div>
          <div class="w-half inline-block align-top">
            <div
              class="text-center mb-2 fs-17"
            >{{ form && form.mdt_time ? getHMDMYVN(form.mdt_time) : "&nbsp;" }}</div>
            <div class="text-center uppercase fs-17">
              {{
              $t(
              "multidisciplinary_board.lbl_mems_preside",
              getLangCode
              )
              }}
            </div>
            <div class="text-center mt-12 fs-17">{{ form.preside }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
::v-deep {
  body,
  html {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 0em;
    color: #000000 !important;
  }
  p {
    margin-top: 0;
  }
  .content-none {
    width: 100%;
    border-bottom: 2px dotted;
  }
  .whitespace-nowrap {
    white-space: nowrap;
  }
  .styleText {
    word-wrap: break-word;
  }
  .text-italic {
    font-style: italic;
  }
  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
  }
  .top-0 {
    top: 0;
  }
  .left-0 {
    left: 0;
  }
  .px-8 {
    padding-right: 3rem;
    padding-left: 3rem;
  }
  .overflow-hidden {
    overflow: hidden;
  }
  .float-left {
    float: left;
  }
  .float-right {
    float: right;
  }
  .float-none {
    float: none;
  }
  .block {
    display: block;
  }
  .inline-block {
    display: inline-block !important;
  }
  .flex {
    display: flex;
  }
  .flex-col {
    flex-direction: column;
  }
  .align-top {
    vertical-align: top;
  }
  .text-center {
    text-align: center;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-between {
    justify-content: space-between;
  }
  .items-start {
    align-items: flex-start;
  }
  .items-center {
    align-items: center;
  }
  .col-6 {
    width: 50% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .col-12 {
    width: 100% !important;
    display: inline-block !important;
    vertical-align: top;
  }
  .w-full {
    width: 100% !important;
  }
  .w-half {
    width: 50% !important;
  }
  .w-one-third {
    width: 33.333% !important;
  }
  .w-two-thirds {
    width: 66.667% !important;
  }
  .font-bold {
    font-weight: 700;
  }
  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .pl-12 {
    padding-left: 3rem;
  }
  .pl-16 {
    padding-left: 4rem;
  }
  .pr-12 {
    padding-right: 3rem;
  }
  .pr-16 {
    padding-right: 4rem;
  }
  .mt-3 {
    margin-top: 0.75rem;
  }
  .mt-6 {
    margin-top: 1.5rem;
  }
  .mt-8 {
    margin-top: 2rem;
  }
  .mt-12 {
    margin-top: 3rem;
  }
  .mt-16 {
    margin-top: 4rem;
  }
  .mt-20 {
    margin-top: 5rem;
  }
  .mt-24 {
    margin-top: 6rem;
  }
  .ml-1 {
    margin-left: 0.25rem;
  }
  .mr-1 {
    margin-right: 0.25rem;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  .mb-1 {
    margin-bottom: 0.25rem;
  }
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  .mb-3 {
    margin-bottom: 0.75rem;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  .mb-8 {
    margin-bottom: 2rem;
  }
  .hodo-table figure {
    margin: 0 0 1rem !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }
  .hodo-table table thead th {
    text-transform: uppercase !important;
  }
  .hodo-table table td,
  .hodo-table table th {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
  .box-number {
    border: 1px solid;
    height: 1.5rem !important;
  }
  .box-item {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
    height: 1.5rem !important;
    width: 1.5rem !important;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-item-bhyt {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
    height: 1.5rem !important;
    padding: 0.5rem;
    border-right: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-item-bhyt:last-child,
  .box-item:last-child {
    border-right: none;
  }
  .checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
  }
}
</style>

<script>
import _ from 'lodash'
import mdtUtils from '../../utils/mdtUtils'
import { mixinPrint } from '../../utils/mixinPrint'
import appUtils from '../../utils/appUtils'

export default {
  name: 'TempBienBan',
  mixins: [mixinPrint],
  props: [
    'form',
    'save_pdf',
    'mdt_disease',
    'room_info',
    'show',
    'print',
    'checkLang',
    'multiLangData'
  ],
  data () {
    return {
      clinic: null,
      mdtUtils,
      saveLang: this.checkLang || 1,
      aliasData: null
    }
  },
  mounted () {
    if (this.room_info) {
      this.clinic = this.room_info.clinic
    }
  },
  computed: {
    getLangCode () {
      return appUtils.getLangCode(this.saveLang)
    },
    multiLanguageData () {
      if (this.form) {
        let result = {
          conclusion: '',
          diagnostic: '',
          treatment: '',
          treatment_progress: '',
          treatment_time: ''
        }
        result = {
          conclusion: this.form.conclusion,
          diagnostic: this.patientDiagnostic,
          treatment: this.form.treatment,
          treatment_progress: this.form.treatment_progress,
          treatment_time: this.form.treatment_time
        }
        return result
      } else {
        return this.form
      }
    },
    patientDiagnostic () {
      if (this.mdt_disease) {
        if (this.mdt_disease.multilang?.length) {
          const langId = this.saveLang
          const r = this.mdt_disease.multilang.find(
            item => item.lang === langId
          )
          if (!r) return this.mdt_disease.patient_diagnostic
          else return r.patient_diagnostic
        }
        return this.mdt_disease.patient_diagnostic
      }
      return ''
    }
  },
  watch: {
    save_pdf (count) {
      this.exportPdf(true)
    },
    print () {
      this.onPrintNe()
    },
    room_info (data) {
      if (!data) return
      this.clinic = data.clinic
    },
    checkLang () {
      this.updateLang()
    },
    mdt_disease () {
      if (this.mdt_disease.alias) {
        this.aliasData = this.mdt_disease.alias
      }
    }
  },
  methods: {
    test () {
      console.log('checkLang', this.checkLang)
    },
    updateLang () {
      this.saveLang = this.checkLang
    },
    async exportPdf (save = false) {
      const self = this
      const interval = 50
      let promise = Promise.resolve()
      let base64Data = []

      if (save) {
        _.forEach(self.multiLangData, (value, index) => {
          promise = promise.then(function () {
            self.saveLang = Number(value.lang)

            _.delay(function () {
              const bodyHTML = self.$refs.documentPrint.innerHTML
              const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })

              base64Data.push(appUtils.base64.decode(tempHTML))
            }, interval)

            return new Promise(function (resolve) {
              setTimeout(resolve, interval)
            })
          })
        })

        self.$emit('onSubmiting', base64Data)
      }
    },
    getLogo (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + path
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    showSaveSuccess () {
      this.$toast.open({
        message: this.$t('multidisciplinary_board.lbl_saved_msg'),
        type: 'success'
      })
    },
    getDate (date) {
      return this.moment(date).format('DD/MM/YYYY')
    },
    getDateTime (date) {
      let string = this.moment(date).locale(this.getLangCode)
      if (this.saveLang === 1) {
        string = string.format('HH:mm-DD/MM/YYYY')
        string = string.replace('-', ' ngày ')
        string = string.replace('00:00', '--:--')
        return string
      } else {
        return string.format('LLL')
      }
    },
    getDateTime2 (date_time) {
      return window.moment(date_time).format('YYYY-MM-DD HH:mm')
    },
    getDateTime3 (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY HH:mm')
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.getLangCode)
      if (this.saveLang === 1) {
        const dt = this.moment(date)
        // let hm = dt.clone().format('HH:mm')
        const d = dt.clone().format('DD')
        const m = dt.clone().format('MM')
        const y = dt.clone().format('YYYY')
        return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    onPrintNe () {
      const bodyHTML = this.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({
        bodyHTML,
        lang: this.getLangCode
      })

      this.onPrintTBBHC(tempHTML, true)
    }
  }
}
</script>
