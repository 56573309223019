<template>
  <div
    id="TempPhieuChucNangSong"
    :class="false ? '' : 'absolute top-0 left-0 w-full h-0 opacity-0 overflow-hidden pointer-events-none'"
    style="z-index: -1"
  >
    <div ref="documentPrint" class="bg-white text-black container mx-auto">
      <div class="mb-2">
        <div class="row">
          <div class="col-3 uppercase">
            <div style="width: 200px">
              <div
                class="fs-14 font-bold"
              >{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.authority_in_charge : "&nbsp;" }}</div>
              <div
                class="fs-14 font-bold"
              >{{ $globalClinic && $globalClinic.organization ? $globalClinic.organization.name : "&nbsp;" }}</div>
              <div class="fs-12 font-bold">{{ $globalClinic && $globalClinic.name || "&nbsp;" }}</div>
            </div>
          </div>
          <div class="col-6 text-center">
            <div class="uppercase font-bold text-lg mt-2">PHIẾU THEO DÕI CHỨC NĂNG SỐNG</div>
          </div>
          <div class="col-3 text-right">
            <div class="inline-block text-left">
              <div class="fs-14">MS: {{ '10/BV-01' || '................................' }}</div>
              <!-- <div>Mã BN: {{ person && person.person_ref_id || '..........................' }}</div> -->
              <div class="fs-14">Số vào viện: {{ person && person.ref_id || '...................' }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-2 fs-16">
          <div class="row">
            <div class="col-6">
              <div>
                <label class="mr-1">Họ tên người bệnh:</label>
                <span class="font-bold uppercase">{{ person.name }}</span>
              </div>
            </div>
            <div class="col-3">
              <label class="mr-1">Tuổi:</label>
              <span class="font-bold">{{ mdtUtils.getNumberAges(new Date(person.birthday)) }}</span>
            </div>
            <div class="col-3">
              <label class="mr-1">Giới tính:</label>
              <span class="font-bold">{{ mdtUtils.getGender(person.gender, 'vi') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div>
                <label class="mr-1">Buồng:</label>
                <span class="font-bold uppercase">{{ person.admission_room }}</span>
              </div>
            </div>
            <div class="col-6">
              <label class="mr-1">Giường:</label>
              <span class="font-bold">{{ person.admission_bed }}</span>
            </div>
          </div>
          <div>
            <div class="overflow-hidden">
              <label class="float-left mr-1">Chẩn đoán:</label>
              <div v-html="medicalRecordMain && medicalRecordMain.treat_dept_main_name || ''" />
            </div>
          </div>
        </div>
        <div class="hodo-table">
          <table
            class="text-center fs-10 border-none"
            v-if="signsTemp && signsTemp.length && headerSignsTemp && headerSignsTemp.length"
            cellpadding="0"
          >
            <thead>
              <tr>
                <th colspan="2" class="font-bold" style="width: 87.4px;">Ngày tháng</th>
                <th
                  v-for="(s, index) in headerSignsTemp"
                  :key="`${index}_th_record_time`"
                  :colspan="(s.data && s.data.length) || 1"
                  class="font-bold"
                  :style="{
                    width: (s.data && s.data.length) ? `${s.data.length * 34.785}px` : `34.785px`
                  }"
                >{{ s.date && appUtils.formatSimpleDate(s.date) }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="whitespace-nowrap font-bold">
                  <div>Mạch</div>
                  <div>Lần/phút</div>
                </td>
                <td class="whitespace-nowrap font-bold">
                  <div>Nhiệt độ</div>
                  <div>C</div>
                </td>
                <td
                  v-for="(s, index) in signsTemp"
                  :key="`${index}_td_record_time`"
                  class="font-bold"
                  style="width: 34.785px;"
                >{{ s.record_time && appUtils.formatSimpleTime(s.record_time) }}</td>
              </tr>
              <tr>
                <td colspan="20" style="padding: 0 !important;">
                  <div class="relative flex">
                    <div
                      class="absolute w-full"
                      style="border-bottom: 2px solid black; bottom: 184px; z-index: 1;"
                    ></div>
                    <div style="width: 87.4px; border-right: 1px solid black;">
                      <div
                        class="flex items-stretch"
                        :style="index === 0 ? {
                          height: '47px',
                          borderBottom: index < yAxesLeft.data.length - 1 ? '1px solid black' : ''
                        } : {
                          height: '92.12px',
                          borderBottom: index < yAxesLeft.data.length - 1 ? '1px solid black' : ''
                        }"
                        v-for="(item, index) in yAxesLeft.data"
                        :key="`${item}_${index}_tr`"
                      >
                        <div class="flex" style="width: 45.81px; border-right: 1px solid black;">
                          <span class="m-auto">{{ item }}</span>
                        </div>
                        <div class="flex" style="width: 42.13px;">
                          <span class="m-auto">{{ yAxesRight.data[index] }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      style="width: 625.06px;"
                      class="overflow-hidden relative text-left"
                      v-if="imageChart.length"
                    >
                      <template v-for="(item, index) in imageChart">
                        <img
                          v-if="item.type === 1"
                          :key="index"
                          class="relative z-10 bg-contain object-contain"
                          style="margin-left: -17.36px; margin-top: 47px;"
                          :src="item.src"
                          :alt="item.alt"
                        />
                        <div
                          class="flex absolute top-0 left-0 z-0 w-full"
                          :key="index"
                          v-if="item.type === 2"
                        >
                          <div
                            class="flex absolute bg-white top-0 left-0 z-0 w-full"
                            style="padding-top: 46.45px"
                          >
                            <img class="bg-contain object-contain" :src="item.src" :alt="item.alt" />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                          </div>
                          <div
                            class="flex absolute bg-white top-0 left-0 z-0 w-full"
                            style="transform: scaleY(-1)"
                          >
                            <img class="bg-contain object-contain" :src="item.src" :alt="item.alt" />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                            <img
                              class="bg-contain object-contain"
                              style="margin-left: -35px;"
                              :src="item.src"
                              :alt="item.alt"
                            />
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-left font-bold">Huyết áp (mmHg)</td>
                <td
                  v-for="(s, index) in signsTemp"
                  :key="`${index}_td_systolic_diastolic_pressure`"
                  class="text-center"
                >
                  <span
                    class="inline-block"
                    style="border-bottom: 1px solid black;"
                  >{{ s.systolic_pressure &&s.systolic_pressure }}</span>
                  <span class="block">{{ s.diastolic_pressure &&s.diastolic_pressure }}</span>
                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-left font-bold">Cân nặng (Kg)</td>
                <td v-for="(s, index) in signsTemp" :key="`${index}_td_weight`">{{ s.weight }}</td>
              </tr>
              <tr>
                <td colspan="2" class="text-left font-bold">Nhịp thở(lần/phút)</td>
                <td v-for="(s, index) in signsTemp" :key="`${index}_td_breath`">{{ s.breath }}</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
                <td v-for="(s, index) in signsTemp" :key="`${index}_td_empty_1`">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
                <td v-for="(s, index) in signsTemp" :key="`${index}_td_empty_2`">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2" class="font-bold">
                  <div class="mb-4">Y tá - Điều dưỡng</div>
                  <div class="whitespace-nowrap">Ký và ghi rõ họ tên</div>
                </td>
                <td v-for="(s, index) in signsTemp" :key="`${index}_td_empty_3`">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .container {
    width: 715px;
    margin: 0;
    padding: 0;
  }
  .font-bold {
    font-weight: 700 !important;
  }
  .hodo-table table {
    border-collapse: collapse !important;
    &.border-none {
      border: none !important;
      border-top: 1px solid black !important;
      border-left: 1px solid black !important;
    }
    th,
    td {
      padding: 4px 2px !important;
      border: none !important;
      border-right: 1px solid black !important;
      border-bottom: 1px solid black !important;
      border-collapse: collapse !important;
      &.custom-padding {
        padding: 4px 2px !important;
      }
    }
  }
  @each $size in (10, 16) {
    .fs-#{$size} {
      font-size: #{$size}px !important;
    }
  }
}
</style>

<script>
import html2canvas from 'html2canvas'
import { mapState } from 'vuex'
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import uploadImagePrint from '../../utils/uploadImagePrint'

export default {
  name: 'TempPhieuChucNangSong',
  props: ['imageChart', 'signData'],
  data () {
    return {
      moment: window.moment,
      appUtils,
      mdtUtils,
      signsTemp: [...this.signData] || [],
      headerSignsTemp: [],
      yAxesLeft: {
        stepSize: 20,
        min: 40,
        max: 160,
        data: []
      },
      yAxesRight: {
        stepSize: 1,
        min: 35,
        max: 41,
        data: []
      }
    }
  },
  mounted () {
    this.setupHeaderSignsTemp()

    const signsTotal = this.signsTemp.length
    if (signsTotal < 18) {
      for (let i = 0; i < 18 - signsTotal; i++) {
        this.signsTemp.push('')
      }
    }

    for (let i = 0; i < this.yAxesLeft.min; i++) {
      const valueLeft = this.yAxesLeft.max - this.yAxesLeft.stepSize * i
      const valueRight = this.yAxesRight.max - this.yAxesRight.stepSize * i

      if (valueLeft < this.yAxesLeft.min || valueRight < this.yAxesRight.min) {
        return
      }

      this.yAxesLeft.data.push(valueLeft)
      this.yAxesRight.data.push(valueRight)
    }
  },
  computed: {
    ...mapState({
      person: state => state.person,
      medicalRecordMain: state => state.medicalRecordMain
    })
  },
  watch: {
    imageChart (data) {
      setTimeout(() => {
        this.exportPdf()
      }, 50)
    },
    signData: {
      handler (data) {
        this.signsTemp = data
        const signsTotal = this.signsTemp.length
        if (signsTotal < 18) {
          for (let i = 0; i < 18 - signsTotal; i++) {
            this.signsTemp.push('')
          }
        }
      },
      deep: true
    }
  },
  methods: {
    setupHeaderSignsTemp () {
      const signsTotal = [...this.signData]?.length
      if (!signsTotal) return
      const filteredObj = [...this.signData]?.reduce((res, curr) => {
        const newDate = window
          .moment(curr.record_time)
          .format('YYYY-MM-DD')
          .toString()

        if (res[newDate]) res[newDate].push(curr)
        else Object.assign(res, { [newDate]: [curr] })

        return res
      }, {})

      const newSignsData = Object.entries(filteredObj).map(([key, value]) => {
        return {
          date: key,
          data: value
        }
      })

      if (signsTotal < 18) {
        for (let i = 0; i < 18 - signsTotal; i++) {
          newSignsData.push('')
        }
      }

      this.headerSignsTemp = [...newSignsData]
    },
    async captureImageCanvas (doc) {
      const options = {
        dpi: 2000,
        scale: 10,
        backgroundColor: '#ffffff',
        image: { type: 'jpeg', quality: 1 },
        letterRendering: true,
        allowTaint: true,
        logging: true,
        useCORS: true
      }
      const dataCanvas = await html2canvas(doc, options)
        .then(canvas => {
          const imgBase64Data = canvas.toDataURL('image/jpeg')
          return imgBase64Data
        })
        .catch(() => {})

      return dataCanvas
    },
    async savePdfToS3 (base64) {
      if (!base64) return

      try {
        const payload = {
          content: base64,
          fileName: `${appUtils.convertSlug(
            `${this.$t('Phiếu chức năng sống')}`
          )}_`,
          path: 'vitalsign',
          type: 'print_form',
          type_id: 'print_form_id',
          uuid: ''
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.$emit('setIsLoading', false)
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async exportPdf () {
      const self = this
      const refDocument = [self.$refs.documentPrint]
      await Promise.all(
        refDocument?.map(async doc => {
          const imgBase64Data = await self.captureImageCanvas(doc)
          const res = await uploadImagePrint.fileUploadHandler(imgBase64Data)
          const wrap = document.createElement('div')
          const div = document.createElement('div')
          const img = document.createElement('img')

          img.src = res
          img.style.maxWidth = '100%'
          div.appendChild(img)
          wrap.appendChild(div)

          return wrap.innerHTML
        })
      ).then(async res => {
        const tempHTML = appUtils.templateHTMLPrint({
          bodyHTML: res
        })
        const base64Data = appUtils.base64.decode(tempHTML)

        await self.savePdfToS3(base64Data)
      })
    }
  }
}
</script>
