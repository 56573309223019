const person = {
  namespaced: true,
  state: {
    mdtRoomDetail: {}
  },
  getters: {
    getMdtRoomDetail (state) {
      return state.mdtRoomDetail
    }
  },
  mutations: {
    setMdtRoomDetail (state, data) {
      state.mdtRoomDetail = data
    }
  },
  actions: {}
}

export default person
