<template>
  <base-temp
    :show="show"
    id="indicationUltrasoundTemp"
    :title="$t('Phiếu kết quả')"
    :code="formData.indication.code"
    :form-data="formData"
    :save_pdf="save_pdf"
    :check-lang="checkLang"
  >
    <div class="mb-8" v-if="form">
      <template
        v-if="
          form.type === 1 && tableData.length && Object.keys(metaData).length
        "
      >
        <div class="block mb-2">
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Người lấy mẫu") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.collection_by_employee_name"
              v-html="metaData.collection_by_employee_name"
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Thời gian lấy mẫu") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.collection_date_time"
              v-html="
                metaData &&
                appUtils.formatDateTime(metaData.collection_date_time)
              "
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
        </div>
        <div class="block mb-2">
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Người hoàn thành") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.completed_by_full_name"
              v-html="metaData.completed_by_full_name"
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Thời gian hoàn thành") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.completed_date_time"
              v-html="
                metaData &&
                appUtils.formatDateTime(metaData.completed_date_time)
              "
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
        </div>
      </template>
      <template v-else-if="form.type === 2 && Object.keys(metaData).length">
        <div class="block mb-2">
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Kỹ thuật viên 1") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.first_tech_employee_name"
              v-html="metaData.first_tech_employee_name"
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Kỹ thuật viên 2") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.second_tech_employee_name"
              v-html="metaData.second_tech_employee_name"
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
        </div>
        <div class="block mb-2">
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Bác sĩ đọc kết quả") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.summary_employee_name"
              v-html="metaData.summary_employee_name"
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
          <div class="w-half inline-block">
            <div class="font-bold fs-18">{{ $t("Ngày đọc kết quả") }}:</div>
            <div
              class="hodo-table overflow-hidden fs-18 content-size"
              v-if="metaData.completed_date_time"
              v-html="
                metaData &&
                appUtils.formatDateTime(metaData.completed_date_time)
              "
              :class="isHTML ? 'border-b-cs' : ''"
            ></div>
          </div>
        </div>
      </template>
      <div class="mb-2">
        <div class="font-bold fs-18">{{ $t("Mô tả") }}:</div>
        <div
          class="hodo-table overflow-hidden fs-18 content-size"
          v-if="
            form.indication.examination_results &&
            form.indication.examination_results.conclusion
          "
          v-html="form.indication.examination_results.conclusion.description"
          :class="isHTML ? 'border-b-cs' : ''"
        ></div>
      </div>
      <div
        class="mb-2 hodo-table"
        v-if="
          form.type === 1 && tableData.length && Object.keys(metaData).length
        "
      >
        <div class="font-bold fs-18">{{ $t("Kết quả xét nghiệm") }}:</div>
        <!-- Bảng kết quả xét nghiệm -->
        <table>
          <thead>
            <tr>
              <th class="text-center">
                <span>Xét nghiệm</span>
              </th>
              <th class="text-center">
                <span>Kết quả</span>
              </th>
              <th class="text-center">
                <span>Đơn vị</span>
              </th>
              <th class="text-center">
                <span>Bình thường</span>
              </th>
              <th class="text-center">
                <span>Máy XN</span>
              </th>
              <th class="text-center">
                <span>Ghi chú</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in tableData" :key="`result-${index}`">
              <template v-if="row.is_full_row">
                <td :colspan="6">
                  <span class="font-bold">{{ row.test_name }}</span>
                </td>
              </template>
              <template v-else>
                <td :colspan="1">
                  <span>{{ row.test_name }}</span>
                </td>
                <td>
                  <template>
                    <span class="block text-center" :class="{ 'font-bold': row.is_alert }">
                      <span
                        :style="
                          row.low_high === 'H'
                            ? 'color: red !important'
                            : row.low_high === 'L'
                            ? 'color: blue !important'
                            : ''
                        "
                      >{{ row.result }}</span>
                      <span
                        class="ml-1"
                        :style="
                          row.low_high === 'H'
                            ? 'color: red !important'
                            : row.low_high === 'L'
                            ? 'color: blue !important'
                            : ''
                        "
                        v-if="row.low_high !== ''"
                      >{{ row.low_high ? `(${row.low_high})` : "" }}</span>
                    </span>
                  </template>
                </td>
                <td>
                  <span class="block text-center">{{ row.unit }}</span>
                </td>
                <td>
                  <span class="block text-center">{{ row.normal_rate }}</span>
                </td>
                <td>
                  <span class="block text-center">{{ row.device }}</span>
                </td>
                <td>
                  <span>{{ row.note }}</span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <!-- End of Bảng kết quả xét nghiệm -->
      </div>
      <div class="mb-2" v-else-if="form.type === 2 && Object.keys(metaData).length">
        <div class="font-bold fs-18">{{ $t("Kết quả chẩn đoán") }}:</div>
        <div
          class="hodo-table overflow-hidden fs-18 content-size"
          v-if="
            form.indication.examination_results &&
            form.indication.examination_results.description
          "
          v-html="form.indication.examination_results.description"
        ></div>
      </div>
      <div class="mb-2">
        <div
          class="font-bold mb-2 fs-18"
        >{{ $t(isXQuang ? "Lời dặn của BS chuyên khoa" : "Kết luận") }}:</div>
        <div
          class="hodo-table fs-18 content-size"
          v-if="
            form.indication.examination_results &&
            form.indication.examination_results.conclusion
          "
          v-html="form.indication.examination_results.conclusion.conclusion"
        ></div>
      </div>
      <div class="mb-2">
        <div class="font-bold mb-2 fs-18">Nhận định kết quả:</div>
        <div
          class="hodo-table fs-18 content-size"
          v-if="
            form.indication.examination_results &&
            form.indication.examination_results.conclusion
          "
          v-html="form.indication.examination_results.conclusion.indentify"
        ></div>
      </div>
    </div>
  </base-temp>
</template>
<style lang="scss" scoped>
::v-deep {
  body,
  html {
    font-family: 'Times New Roman', Tinos, Times, serif !important;
  }

  p {
    margin-top: 0;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .left-0 {
    left: 0;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .hodo-table figure {
    margin: 0 0 1rem !important;
  }

  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #7d7d7e !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #7d7d7e !important;
  }
}
</style>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import { mixinPrint } from '../../utils/mixinPrint'
import BaseTemp from '../BaseTemp.vue'

export default {
  name: 'TempIndicationUltrasound',
  mixins: [mixinPrint],
  props: {
    show: Boolean,
    formData: {
      type: Object,
      required: true
    },
    save_pdf: {
      type: Number,
      required: true
    },
    checkLang: {
      type: Number
    },
    isXQuang: Boolean,
    tableData: {
      type: Array
    }
  },
  components: {
    BaseTemp
  },
  data () {
    return {
      loading: false,
      form: null,
      appUtils,
      mdtUtils,
      metaData: this.tableData[0]?.meta_data || {}
    }
  },
  mounted () {
    if (this.formData) {
      this.form = this.formData
    }

    if (this.tableData.length) {
      this.metaData = this.tableData[0]?.meta_data || {}
    }
  },
  computed: {
    isHTML () {
      const content = this.form?.indication?.examination_results?.conclusion
        ?.description
      const regex = /<table\b[^>]*>/

      return regex.test(content)
    }
  },
  watch: {
    formData (data) {
      this.form = data
    },
    tableData: {
      handler (data) {
        // eslint-disable-next-line no-mixed-operators
        this.metaData = (data.length && data[0]?.meta_data) || {}
      },
      deep: true
    }
  },
  methods: {}
}
</script>
