import Vue from 'vue'
import Vuex from 'vuex'
import app from './store/app'
import appointmentStore from './store/appointment'
import basic from './store/basic'
import basicTwo from './store/basicTwo'
import chatConversation from './store/chat'
import clinicDoctorStore from './store/clinicDoctor'
import consultingRoomStore from './store/consultingRoom'
import emrStore from './store/emrStore'
import mdtStore from './store/mdtStore'
import person from './store/person'
import upToDate from './store/uptodate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    app,
    person,
    basic,
    basicTwo,
    appointmentStore,
    consultingRoomStore,
    clinicDoctorStore,
    upToDate,
    mdtStore,
    chatConversation,
    emrStore
  },
  state: {
    settings: [],
    isMetaFetched: false,
    person: {},
    personHR: {},
    concept: {
      user: null,
      ws: null,
      mode: 1
    },
    medicalRecordMain: {},
    emrData: {},
    listWS: []
  },
  getters: {
    getConcept (state) {
      return state.concept
    },
    getListWS (state) {
      return state.listWS
    }
  },
  mutations: {
    setPerson (state, { person }) {
      state.person = person
    },
    setPersonHR (state, { personHR }) {
      state.personHR = personHR
    },
    setConcept (state, { concept }) {
      state.concept = concept
    },
    setMedicalRecordMain (state, { medicalRecordMain }) {
      state.medicalRecordMain = medicalRecordMain
    },
    setEmrData (state, { emrData }) {
      state.emrData = emrData
    },
    setListWS (state, { listWS }) {
      state.listWS = listWS
    }
  },
  actions: {
    setPerson (context, person) {
      context.commit('setPerson', { person })
    },
    setPersonHR (context, personHR) {
      context.commit('setPersonHR', { personHR })
    },
    setConcept (context, concept) {
      context.commit('setConcept', { concept })
    },
    setMedicalRecordMain (context, medicalRecordMain) {
      context.commit('setMedicalRecordMain', { medicalRecordMain })
    },
    setEmrData (context, emrData) {
      context.commit('setEmrData', { emrData })
    },
    setListWS (context, listWS) {
      context.commit('setListWS', { listWS })
    }
  }
})
