const person = {
  namespaced: true,
  state: {
    person_edit: {}
  },
  mutations: {
    setPersonEdit (state, {person_edit}) {
      state.person_edit = person_edit
    }
  },
  actions: {
    setPersonEdit (context, {person_edit}) {
      context.commit('setPersonEdit', {person_edit})
    }
  }
}

export default person
