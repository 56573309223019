<template>
  <div
    :class="false ? '' : 'absolute top-0 left-0 w-full h-0 opacity-0 overflow-hidden pointer-events-none'"
    style="z-index: -1"
  >
    <div class="bg-white text-black conntainer mx-auto" ref="documente">
      <div id="TTTQMTemp" class="pl-3 pr-3 fs-14">
        <div>
          <div class="bs-callout bs-callout row mb-2">
            <div class="col-3 uppercase">
              <div v-if="clinic" class="inline-block align-top uppercase">
                <div class="flex flex-col" style="width: 200px">
                  <p
                    class="mb-0 fs-14 font-bold"
                    v-if="clinic.organization && clinic.organization.authority_in_charge"
                  >{{ clinic.organization.authority_in_charge }}</p>
                  <p
                    class="mb-0 fs-14 font-bold"
                    v-if="clinic.organization && clinic.organization.name"
                  >{{ clinic.organization && clinic.organization.name }}</p>
                  <p class="mb-0 fs-12 font-bold">{{ form.dept || "" }}</p>
                </div>
              </div>
              <!-- <div class="inline-block" style="width: 300px">
                <p class="mb-0 fs-13 font-bold">
                  {{ clinic && clinic.organization ? clinic.organization.authority_in_charge
                  : "&nbsp;" }}
                </p>
                <p
                  class="mb-0 fs-13 font-bold"
                >{{ clinic && clinic.organization ? clinic.organization.name : "&nbsp;" }}</p>
                <p class="mb-0 fs-11 font-bold">{{ form.dept }}</p>
              </div>-->
            </div>
            <div class="col-6 text-center">
              <p class="robo-12-700 mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
              <p class="robo-12-700 mb-0">Độc lập - Tự do - Hạnh phúc</p>
              <p class="robo-12-700 mb-0">------------------------------</p>
            </div>
            <div class="col-3 d-flex justify-content-end text-right">
              <div class="whitespace-nowrap inline-block text-left">
                <div class="robo-12-400">MS: {{ '42/BV-01' || '................................' }}</div>
                <!-- <div
                  class="robo-12-400"
                >Mã BN: {{ mdt_disease && mdt_disease.medical_record && mdt_disease.medical_record.person_ref_id || '..........................' }}</div>-->
                <div class="robo-12-400">Mã BN: {{ '' || '..........................' }}</div>
                <div class="robo-12-400">
                  Số vào viện: {{ form.code || (mdt_disease && mdt_disease.medical_record &&
                  mdt_disease.medical_record.ref_id) || '...................' }}
                </div>
              </div>
            </div>
          </div>
          <div class="bs-callout bs-callout">
            <p class="robo-18-700 text-center font-weight-bold">TÓM TẮT THÔNG QUA MỔ</p>
          </div>
          <div class="bs-callout bs-callout mb-2">
            <div class="row">
              <div class="col-6">
                <p class="robo-12-400 mb-0">
                  Họ tên người bệnh:
                  <span class="fw-700 text-uppercase">
                    {{
                    form.name
                    }}
                  </span>
                </p>
                <p class="robo-12-400 mb-0">Địa chỉ: {{ form.address }}</p>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <p class="robo-12-400 mb-0">
                      Tuổi:
                      <span class="fw-700">
                        {{
                        mdt_disease &&
                        mdt_disease.patient_birthday &&
                        mdtUtils.getAges(
                        new Date(mdt_disease.patient_birthday)
                        )
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="robo-12-400 mb-0">
                      Nam/Nữ:
                      <span class="fw-700">
                        {{
                        mdtUtils.getGender(form.sex)
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="col-12">
                    <p class="robo-12-400 mb-0">Khoa: {{ form.dept }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 robo-12-400">
                <span class="mr-1 mb-0">Buồng:</span>
                <span>{{ form && form.admission_room }}</span>
              </div>
              <div class="col-6 robo-12-400 mb-0">
                <span class="mr-1">Giường:</span>
                <span>{{ form && form.admission_bed }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-4 robo-12-400 whitespace-nowrap">
                <span>
                  {{
                  $t(
                  "multidisciplinary_board.lbl_treatment_period_from"
                  )
                  }}:
                </span>
                <span>{{ form && form.treatment_time_start && getDateTime(form.treatment_time_start) }}</span>
              </div>
              <div class="col-4 robo-12-400 whitespace-nowrap">
                <span>
                  {{
                  $t(
                  "multidisciplinary_board.lbl_treatment_period_to"
                  )
                  }}:
                </span>
                <span>{{ form && form.treatment_time_end && getDateTime(form.treatment_time_end) }}</span>
              </div>
              <div class="col-4 robo-12-400 whitespace-nowrap">
                <span class="mr-1">{{ $t('multidisciplinary_board.lbl_mdt_at') }}:</span>
                <span>{{ form && form.mdt_time && getDateTime(form.mdt_time) }}</span>
              </div>
            </div>
            <div class="mb-1 robo-12-400">
              <div class="overflow-hidden">
                <div class="float-left mb-0">{{ $t('multidisciplinary_board.lbl_diagnose') }}:</div>
                <div
                  class="robo-12-400"
                  style="max-width: 100% !important; word-break: break-all !important;"
                  v-html="form && form.diagnostic"
                ></div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-6 robo-12-400  mb-1">
                <span class="mr-1">{{ $t('multidisciplinary_board.lbl_mems_preside') }}:</span>
                <span>{{ form && form.preside }}</span>
              </div>
              <div class="col-6 robo-12-400  mb-1">
                <span class="mr-1">{{ $t('multidisciplinary_board.lbl_mems_type_secretary') }}:</span>
                <span>{{ form && form.secretary }}</span>
              </div>
            </div>
            <div class="mb-1 robo-12-400">
              <div class="overflow-hidden">
                <div
                  class="float-left mr-1 mb-0"
                >{{ $t('multidisciplinary_board.lbl_participant') }}:</div>
                <div
                  class="robo-12-400"
                  v-html="form && form.members && form.members.substring(0, form.members.length - 1)"
                ></div>
              </div>
            </div>-->
          </div>
          <div class="bs-callout bs-callout mb-2">
            <div class="mb-1">
              <div class="robo-12-700 mb-1 text-uppercase">
                I. Tóm tắt quá trình diễn biến bệnh, quá trình điều trị và chăm
                sóc người bệnh
              </div>
              <div
                class="robo-12-400 box-editor"
                style="max-width: 100% !important; word-break: break-all !important;"
                v-html="form.medical_history"
              ></div>
            </div>
            <div>
              <div class="robo-12-700 mb-1">
                <span class="text-uppercase mr-1 robo-12-700">Kết luận:</span>
                <span>(Sau khi đã khám lại và thảo luận)</span>
              </div>
              <div
                class="robo-12-400 box-editor"
                style="max-width: 100% !important; word-break: break-all !important;"
                v-html="form.conclusion"
              ></div>
            </div>
          </div>
          <div class="bs-callout bs-callout">
            <div class="robo-12-700 mb-1 text-uppercase">II. Xét nghiệm</div>
          </div>
          <div class="bs-callout bs-callout row mb-2">
            <div class="col-12 robo-12-400 mb-1">1. Xét nghiệm máu</div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>HST: {{ form.test_hst }}</span>
                <span>g/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>HC: {{ form.test_hc }}</span>
                <span>T/l</span>
              </div>
              <!-- <div class="robo-12-400  mb-1 d-flex justify-content-between whitespace-nowrap">
                <span>Hematocrit: {{ form.test_hematocrit }}</span>
                <span>l/l</span>
              </div>-->
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>HCT: {{ form.test_hct }}</span>
                <span>%</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>TC: {{ form.test_tc }}</span>
                <span>G/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Đông máu CB: {{ form.test_blood_basic }}</span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>BC: {{ form.test_bc }}</span>
                <span>G/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Nhóm Máu: {{ form.test_blood }}</span>
                <span></span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>PT% {{ form.test_pt }}</span>
                <span></span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Ure: {{ form.test_ure }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Creatinin: {{ form.test_creatinin }}</span>
                <span>umol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Glucose: {{ form.test_glucose }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Protit: {{ form.test_protit }}</span>
                <span>g/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Cholesterol: {{ form.test_cholesterol }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Quick: {{ form.test_quick }}</span>
              </div>
            </div>
            <div class="col-3">
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Bilirubin TP: {{ form.test_bilirubin_tp }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>Bilirubin TT: {{ form.test_bilirubin_tt }}</span>
                <span>mmol/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>GOT: {{ form.test_got }}</span>
                <span>U/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>GPT: {{ form.test_gpt }}</span>
                <span>U/l</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>&nbsp;</span>
              </div>
              <div class="robo-12-400 mb-0 d-flex justify-content-between whitespace-nowrap">
                <span>GGT: {{ form.test_ggt }}</span>
              </div>
            </div>
            <div class="robo-12-400 mb-1">2. Nước tiểu: {{ form.urine }}</div>
            <div class="robo-12-400 mb-1">3. Xquang: {{ form.xray }}</div>
            <div class="robo-12-400 mb-1">4. Điện tim: {{ form.electrocar }}</div>
            <div class="robo-12-400 mb-1">5. Siêu âm: {{ form.ultrasound }}</div>
            <div class="robo-12-400">6. Nội soi: {{ form.endoscopy }}</div>
          </div>
          <div class="bs-callout bs-callout">
            <div
              class="mb-1 robo-12-700 text-uppercase"
            >III. Chuẩn bị người bệnh và phương pháp điều trị</div>
          </div>
          <div class="bs-callout bs-callout mb-2">
            <p class="robo-12-400 mb-1">1. Thử phản ứng thuốc:{{ form.drug_reaction }}</p>
            <p class="robo-12-400 mb-1">2. Kháng sinh trước mổ: {{ form.antibiotics }}</p>
            <p class="robo-12-400 mb-1">3. Phương pháp phẫu thuật: {{ form.surgery_method }}</p>
            <p class="robo-12-400 mb-1">4. Phương pháp vô cảm: {{ form.anaesthetic_method }}</p>
            <p class="robo-12-400 mb-1">5. Kíp mổ: {{ form.surgical_team }}</p>
            <p class="robo-12-400 mb-1">6. Kíp gây mê: {{ form.anaesthetic_team }}</p>
            <p class="robo-12-400 mb-1">7. Giải phẫu bệnh: {{ form.anatomic }}</p>
            <p class="robo-12-400 mb-1">
              8. Dự trù máu: {{ form.blood_plan }}
              <span
                class="l-text"
              >Dự kiến ngày mổ: {{ formatDMY(form.surgery_date) }}</span>
            </p>
            <p class="robo-12-400 mb-1">9. Dự kiến khó khăn tiên lượng: {{ form.prognostic }}</p>
          </div>
          <div class="bs-callout bs-callout row text-center">
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Trưởng khoa PT và gmhs</p>
              <p class="robo-12-400">{{ form.gm_sur_anes_name }}</p>
            </div>
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Trưởng khoa ngoại</p>
              <p class="robo-12-400">{{ form.gm_surgery_name }}</p>
            </div>
            <div class="col-4 text-center">
              <p class="robo-12-700 mb-4 pb-3 text-uppercase">Bác sĩ điều trị</p>
              <p class="robo-12-400">{{ form.doctor_name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import mdtUtils from '../../utils/mdtUtils'
import appUtils from '../../utils/appUtils'
import uploadImagePrint from '../../utils/uploadImagePrint'
export default {
  name: 'TTTQMTemplate',
  props: ['form', 'save_pdf', 'room_info', 'show', 'mdt_disease'],
  data () {
    return {
      mdtUtils,
      clinic: this.room_info?.clinic || null
    }
  },
  mounted () {
    this.clinic = this.room_info?.clinic
  },
  watch: {
    save_pdf (count) {
      this.exportPdf()
    },
    room_info (data) {
      if (!data) return
      this.clinic = data.clinic
    }
  },
  methods: {
    async captureImageCanvas (doc) {
      const options = {
        dpi: 2000,
        scale: 10,
        backgroundColor: '#ffffff',
        image: { type: 'jpeg', quality: 1 },
        letterRendering: true,
        allowTaint: true,
        logging: true,
        useCORS: true
      }
      const dataCanvas = await html2canvas(doc, options)
        .then(canvas => {
          const imgBase64Data = canvas.toDataURL('image/jpeg')
          return imgBase64Data
        })
        .catch(() => {})

      return dataCanvas
    },
    async exportPdf () {
      const self = this
      const refDocument = [self.$refs.documente]
      await Promise.all(
        refDocument?.map(async doc => {
          const imgBase64Data = await this.captureImageCanvas(doc)
          const res = await uploadImagePrint.fileUploadHandler(imgBase64Data)
          const wrap = document.createElement('div')
          const div = document.createElement('div')
          const img = document.createElement('img')

          img.src = res
          img.style.maxWidth = '100%'
          div.appendChild(img)
          wrap.appendChild(div)

          return wrap.innerHTML
        })
      ).then(async res => {
        let htmlRaw = ''

        // eslint-disable-next-line no-unused-expressions
        res?.map((img, index) => {
          if (index < res.length - 1) {
            htmlRaw += `${img}<div class="html2pdf__page-break"></div>`
          } else {
            htmlRaw += img
          }
        })

        const tempHTML = appUtils.templateHTMLPrint({
          bodyHTML: `<div class="hodo-print">${htmlRaw}</div>`
        })
        const base64Data = appUtils.base64.decode(tempHTML)

        this.$emit('onSubmiting', base64Data)
      })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    getDateTime (d) {
      let string = this.moment(d).format('HH:mm-DD/MM/YYYY')
      string = string.replace('-', ' ngày ')
      string = string.replace('00:00', '--:--')
      return string
    },
    formatDMY (d) {
      return this.moment(d).format('DD/MM/YYYY')
    },
    getGender (data) {
      return mdtUtils.getGender(data)
    }
  }
}
</script>
<style scoped>
.text-black,
.text-black * {
  color: #000000 !important;
}

.conntainer {
  width: 715px;
  margin: 0;
  padding: 0;
}

.l-text {
  margin-left: 230px;
}

.robo-10-700 {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-11-700 {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-12-700 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-13-700 {
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-12-700 {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-18-700 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-16-700 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
}

.robo-12-400 {
  font-size: 13px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}

.robo-10-400 {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}

.robo-10-400 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
}

.textarea {
  white-space: pre-line;
}

.fs-11 {
  font-size: 11px;
}

.box-editor /deep/ p {
  margin-bottom: 0;
}
</style>
