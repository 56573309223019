const uptodate = {
  namespaced: true,
  state: {
    searchValue: '',
    content: ''
  },
  getters: {
    searchValue (state) {
      return state.searchValue
    }
  },
  mutations: {
    setSearchValue (state, payload) {
      state.searchValue = payload
    },
    setContent (state, data) {
      state.content = data
    }
  },
  actions: {},
  modules: {}
}

export default uptodate
