<template>
  <div class="w-100" ref="outer__zoom">
    <div v-bind:style="{zoom: zoomValue+'%'}">
      <div id="print" ref="print" style="max-width: 531pt;margin: 0 auto;">
        <div id="default__wrapper">
          <div v-if="model && model.padding" class="c13" id="template__form"
               :style="{
            paddingTop: `${model.padding.top}px`,
            paddingBottom: `${model.padding.bottom}px`,
            paddingLeft: `${model.padding.left}px`,
            paddingRight: `${model.padding.right}px`
           }">
            <p class="c24 mb-0"><span class="c17">MEDICAL CERTIFICATE</span></p>
            <p class="c2"><span class="c22 c23">Gi&#7845;y ch&#7913;ng nh&#7853;n b&#7879;nh &aacute;n</span></p>
            <p class="c7"><span class="c11">PATIENT&rsquo;S NAME/ </span>
              <span class="c10">T&ecirc;n b&#7879;nh nh&acirc;n</span>
              <span class="c8">:</span><span class="c4 ml-1">{{ case_item && case_item.person ? case_item.person.name : '' }}</span>
            </p>
            <div class="row">
              <div class="col-6">
                <span class="c11">D.O.B/ </span>
                <span class="c10">Ng&agrave;y sinh</span>
                <span class="c11">:</span><span class="c4 ml-1">{{ case_item && case_item.person ? formatDate(case_item.person.birthday) : '' }}</span>
              </div>
              <div class="col-6">
                <span class="c11">GENDER/</span>
                <span class="c10">Gi&#7899;i t&iacute;nh</span><span class="c11">:</span><span class="c4 ml-1">{{ case_item && case_item.person ? genderFormat(case_item.person.gender) : '' }}</span>
              </div>
            </div>
            <p class="c7"><span class="c11">DIAGNOSIS/ </span><span class="c10">Chu&#7849;n &#273;o&aacute;n</span><span
                    class="c11">: </span></p>
            <p><span class="c4">{{ case_item ? case_item.diagnosis : '' }}</span></p>
            <p class="c7"><span class="c11">State briefly nature of illness or injury (chief complain)/ </span><span
                    class="c10">Nguy&ecirc;n nh&acirc;n &#273;&#7871;n kh&aacute;m</span><span class="c8">: </span></p>
            <p><span class="c4">{{ case_item ? case_item.symptom : '' }}</span></p>
            <p class="c7 c25"><span class="c0"></span></p>
            <p class="c7"><span class="c11">Date of first consulted / </span><span class="c10">Ng&agrave;y &#273;&#7847;u
            ti&ecirc;n &#273;&#7871;n kh&aacute;m</span><span class="c8">:</span><span class="c0 ml-1">{{ case_item ? formatDate(case_item.case_date) : '' }}</span>
            </p>

            <p class="c16 mb-0 h6"><span class="c0">Issued by: </span></p>
            <p class="c19"><span class="c3 c26">X&aacute;c nh&#7853;n b&#7903;i</span></p>
            <p class="c7 c25"><span class="c9"></span></p>
            <p class="c5"><span class="c9"></span></p>
            <p class="c5"><span class="c9"></span></p>
            <div class="w-100">
              <p class="c19 float-to-right"><span
                      class="c15">Issued date: {{ case_item ? formatDate(case_item.case_date) : '' }}</span><br>
                <span class="c15 c22 text-left">Ng&agrave;y</span></p>

            </div>

            <p class="c7 c25"><span class="c9"></span></p>
          </div>
        </div>
      </div>

      <div class="mt-3 text-center">
        <button class="btn bg-pri bd-pri text-white" @click="onPrint"><span class="fa fa-print mr-1"></span>In Thử</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {mixinPrint} from '../../utils/mixinPrint'

  export default {
    name: 'DefaultTemplate',
    mixins: [mixinPrint],
    props: {
      inputs: Object,
      checkboxs: Object,
      case_item: Object
    },
    computed: {
      ...mapState({
        form: state => state.basic.form
      })
    },
    created () {
      window.addEventListener('resize', this.calculateZoom)
    },
    destroyed () {
      window.removeEventListener('resize', this.calculateZoom)
    },
    data () {
      return {
        model: {},
        widthTemplate: 0,
        widthOuter: 0,
        zoomValue: 100
      }
    },
    mounted () {
      let self = this

      self.model = self.inputs ? self.inputs : self.form
      self.calculateZoom()
    },
    methods: {
      calculateZoom () {
        let self = this
        self.widthTemplate = self.$refs.print.clientWidth
        self.widthOuter = (self.$refs.outer__zoom ? self.$refs.outer__zoom.clientWidth : 0)
        const zoomVal = (self.widthOuter / self.widthTemplate) * 100
        self.zoomValue = zoomVal >= 100 ? 100 : zoomVal
      },
      onPrint () {
        // Get HTML to print from element
        const prtHtml = document.getElementById('print').innerHTML

        // Get all stylesheets HTML
        let stylesHtml = ''
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML
        }

        // Open the print window
        const {width, height} = window.screen
        const WinPrint = window.open('', '', `left=0,top=0,width=${width},height=${height},toolbar=0,scrollbars=0,status=0`)

        WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`)

        WinPrint.document.close()
        WinPrint.focus()
        WinPrint.print()
        // WinPrint.close()
      },
      formatDate (date) {
        return window.moment(date).format('DD/MM/YYYY')
      },

      genderFormat (gender) {
        return gender === 1 ? 'Nam' : 'Nữ'
      }
    }
  }
</script>

<style lang="less">
  #default__wrapper {

    .float-to-right {
      float: right !important;
    }

    .c6 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.2;
      orphans: 2;
      widows: 2;
      text-align: center;
      height: 12pt
    }

    .c9 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Arial";
      font-style: normal
    }

    .c5 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 12pt
    }

    .c8 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c14 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Times New Roman";
      font-style: normal
    }

    .c12 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Times New Roman";
      font-style: normal
    }

    .c17 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 20pt;
      font-family: "Arial";
      font-style: normal
    }

    .c0 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 11pt;
      font-family: "Arial";
      font-style: normal
    }

    .c16 {
      padding-top: 18pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c23 {
      color: #000000;
      font-weight: 700;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Arial"
    }

    .c24 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.2;
      orphans: 2;
      widows: 2;
      text-align: center
    }

    .c19 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c2 {
      padding-top: 0pt;
      padding-bottom: 12pt;
      line-height: 1.2;
      orphans: 2;
      widows: 2;
      text-align: center
    }

    .c21 {
      padding-top: 6pt;
      padding-bottom: 0pt;
      line-height: 1.5;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c7 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.5;
      orphans: 2;
      widows: 2;
      text-align: left
    }

    .c10 {
      font-size: 11pt;
      font-family: "Arial";
      font-style: italic;
      font-weight: 700
    }

    .c3 {
      font-size: 11pt;
      font-family: "Arial";
      font-style: italic;
      font-weight: 400
    }

    .c11 {
      font-size: 11pt;
      font-family: "Arial";
      font-weight: 700
    }

    .c13 {
      background-color: #ffffff;
      max-width: 531pt;
      padding: 45pt 22.5pt 72pt 58.5pt
    }

    .c1 {
      font-size: 11pt;
      font-family: "Wingdings";
      font-weight: 400
    }

    .c4 {
      font-size: 11pt;
      font-family: "Arial";
      font-weight: 400
    }

    .c26 {
      color: #000000;
      text-decoration: none;
      vertical-align: baseline
    }

    .c15 {
      font-weight: 400;
      font-family: "Arial"
    }

    .c18 {
      margin-left: 36pt
    }

    .c22 {
      font-style: italic
    }

    .c25 {
      height: 12pt
    }
  }
</style>
