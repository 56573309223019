import Vue from 'vue'

const clinicDoctorStore = {
  namespaced: true,
  state: {
    fetching: false,
    fetched: false,
    clinicDoctors: [],
    clinicDoctorMeta: null,
    listDiseasePrint: []
  },
  getters: {
    allClinicDoctors (state) {
      return state.clinicDoctors
    },
    allMeta (state) {
      return state.clinicDoctorMeta
    },
    getListDiseasePrint (state) {
      return state.listDiseasePrint
    }
  },
  mutations: {
    setClinicDoctors (state, payload) {
      state.clinicDoctors = payload
    },
    setMeta (state, payload) {
      state.clinicDoctorMeta = payload
    },
    setListDiseasePrint (state, payload) {
      state.listDiseasePrint = payload
    }
  },
  actions: {
    async getClinicDoctorsRequest ({ commit, state }, params) {
      state.fetched = false

      if (state.fetched) {
        return
      }

      state.fetching = true

      try {
        const clinicId = Vue.prototype.$clinicId

        await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getClinicDoctor(clinicId, params)
          .then((response) => {
            // console.log(response)

            const { data: results, ...meta } = response.data

            commit('setClinicDoctors', results)
            commit('setMeta', meta)

            state.fetched = true
          })
      } catch (error) {
        console.log(error)
        state.fetching = false
        state.fetched = false
      } finally {
        state.fetching = false
      }
    }
  }
}

export default clinicDoctorStore
