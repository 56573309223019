<template>
  <div
    class="out-side-concluding w-100"
    id="meetConcludingTemp"
    ref="outer__zoom"
  >
    <div v-bind:style="{ zoom: zoomValue + '%' }">
      <div class="meet-concluding" id="documente" ref="documente">
        <div class="row">
          <div class="col-7">
            <p class="robo-10-700 mb-0">{{ clinic && clinic.organization ? clinic.organization.authority_in_charge : "&nbsp;" }}</p>
            <p class="robo-10-400 mb-0">
              {{ clinic && clinic.organization ? clinic.organization.name : "&nbsp;" }}
            </p>
            <p class="robo-10-400 mb-1">{{ (clinic && clinic.name) || " " }}</p>
            <p class="robo-10-400 mb-1">Số {{ form.code }}</p>
          </div>
          <div class="col-5 text-center mb-37">
            <p class="robo-11-700 mb-0">CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM</p>
            <p class="robo-11-700 mb-0">Độc lập - Tự do - Hạnh phúc</p>
            <p class="mb-1">_______________________</p>
            <p class="robo-14-400 font-italic">
              {{ formatViDMY(form.meet_time) }}
            </p>
          </div>
        </div>

        <div class="text-center">
          <p class="robo-18-700 mb-0 text-center">{{ form.title }}</p>
          <p class="robo-16-700 text-center">{{ form.sub_title }}</p>
        </div>

        <div>
          <p class="robo-12-400">
            <span class="robo-12-400 mb-3">{{ form.send_to }}</span>
          </p>
        </div>
        <div>
          <p class="robo-12-700">
            Thời gian:
            <span class="robo-12-400 mb-2">{{
              formatViHmDMY(form.meet_time)
            }}</span>
          </p>
        </div>
        <div>
          <p class="robo-12-700">
            Địa điểm: <span class="robo-12-400">{{ form.location }}</span>
          </p>
        </div>
        <div>
          <p class="robo-12-700 mb-0">Chủ trì:</p>
          <div class="content-box" v-html="form.chairing_list"></div>
        </div>
        <div>
          <p class="robo-12-700 mb-0">Thành phần tham dự:</p>
          <div class="content-box" v-html="form.attendees"></div>
        </div>
        <div>
          <p class="robo-12-700 mb-0">Nội dung cuộc họp:</p>
          <div class="content-box" v-html="form.content"></div>
        </div>

        <div class="row mt-5">
          <div class="col-9">
            <div v-if="form.recipients">
              <p class="font-italic robo-10-700 mb-0">Nơi nhận:</p>
              <div class="content-box-10i" v-html="form.recipients"></div>
            </div>
          </div>
          <div class="col-3 text-center">
            <p class="robo-12-700 mb-5">{{ form.sign_label }}</p>
            <p class="robo-12-400">{{ form.sign_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'MeetConcludingTemplate',
  props: ['form', 'save_pdf', 'meet_room'],
  data () {
    return {
      clinic: null,
      widthTemplate: 0,
      widthOuter: 0,
      zoomValue: 100
    }
  },
  watch: {
    save_pdf (count) {
      this.exportPdf(true)
    },
    meet_room (data) {
      if (!data) return
      this.clinic = data.clinic
      setTimeout(() => {
        this.calculateZoom()
      }, 100)
    }
  },
  created () {
    window.addEventListener('resize', this.calculateZoom)
  },
  destroyed () {
    window.removeEventListener('resize', this.calculateZoom)
  },
  mounted () {
    const self = this
    this.$nextTick(function () {
      window.$('#modalConcluding').on('shown.bs.modal', function (e) {
        setTimeout(() => {
          self.calculateZoom()
        }, 100)
      })
    })
  },
  methods: {
    exportPdf (save = false) {
      const self = this
      const options = {
        margin: [7, 14, 7, 7],
        filename: `meeting_${window.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          logging: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf().from(this.$refs.documente).set(options).toPdf().outputPdf()
        .then((pdf) => {
          const newpdf = btoa(pdf)
          const file = this.dataURLtoFile(
            'data:application/octet-stream;base64,' + newpdf,
            `meeting_${window.moment().unix()}.pdf`
          )
          if (save) {
            self.$emit('onSubmiting', file)
          }
        }).catch((e) => {
          self.$emit('exportError', e)
        })
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    calculateZoom () {
      const self = this
      self.widthTemplate = self.$refs.documente.clientWidth
      self.widthOuter = (self.$refs.outer__zoom ? self.$refs.outer__zoom.clientWidth : 0)
      const zoomVal = (self.widthOuter / self.widthTemplate) * 100
      self.zoomValue = zoomVal
    },
    formatViDMY (date) {
      if (!date) return
      const newDate = window.moment(date)
      const d = newDate.format('DD')
      const m = newDate.format('MM')
      const y = newDate.format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    formatViHmDMY (date) {
      if (!date) return
      const string = window.moment(date).format('HH:mm-DD/MM/YYYY')
      return string.replace('-', ' ngày ')
    }
  }
}
</script>
<style scoped>
.mb-40 {
  margin-bottom: 40px;
}
.mb-26 {
  margin-bottom: 26px;
}
.mb-37 {
  margin-bottom: 37px;
}
.textarea {
  white-space: pre-line;
}
.robo-10-700 {
  font-family: 'Tinos', serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}
.robo-11-700 {
  font-family: 'Tinos', serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
}
.robo-12-700 {
  font-family: 'Tinos', serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.robo-18-700 {
  font-family: 'Tinos', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.robo-16-700 {
  font-family: 'Tinos', serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}
.robo-12-400 {
  font-family: 'Tinos', serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}
.robo-10-400 {
  font-family: 'Tinos', serif;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
}
.robo-14-400 {
  font-family: 'Tinos', serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.meet-concluding {
  width: 642.52px;
  margin: 0;
  padding: 0;
}
.font-italic {
  font-style: italic;
}
.out-side-concluding {
  padding: 16px;
}
</style>
