<template>
  <div ref="documentPrint" id="print" v-show="show">
    <div
      :id="id || `doc_${new Date().getTime()}`"
      class="relative hodo-print"
      v-if="form"
    >
      <div class="block fs-14" v-if="saveLang === 1">
        <div class="row mb-3 px-6">
          <div class="col-6 uppercase">
            <div v-if="clinic" class="inline-block align-top uppercase">
              <div class="flex flex-col" style="width: 200px">
                <!-- <p
                  class="mb-0 fs-14 font-bold"
                  v-if="
                    clinic.organization &&
                    clinic.organization.authority_in_charge
                  "
                >{{ clinic.organization.authority_in_charge }}</p>
                <p
                  class="mb-0 fs-14 font-bold"
                  v-if="clinic.organization && clinic.organization.name"
                >{{ clinic.organization.name }}</p>
                <p class="mb-0 fs-14 font-bold" v-if="clinic.name">{{ clinic.name }}</p>-->
                <img
                  :src="
                    appUtils.getImageURL(
                      clinic.organization && clinic.organization.logo
                    )
                  "
                  alt
                />
                <!-- <p
                  class="mb-0 fs-17 font-bold"
                  v-if="clinic.address"
                >Địa chỉ: {{ clinic.address || "" }}</p>
                <p
                  class="mb-0 fs-17 font-bold"
                  v-if="clinic.phone"
                >Số điện thoại: {{ clinic.phone || "" }}</p>
                <p class="mb-0 fs-17 font-bold" v-if="clinic.email">Email: {{ clinic.email || "" }}</p>-->
              </div>
            </div>
          </div>
          <!-- <div class="col-6 text-center">
            <div class="fs-16 font-bold mb-0">
              CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
            </div>
            <div class="fs-16 font-bold mb-0">Độc lập - Tự do - Hạnh phúc</div>
            <div class="fs-16 font-bold mb-0">
              ------------------------------
            </div>
          </div>-->
          <div class="col-2"></div>
          <div class="col-4 d-flex justify-content-end text-right">
            <!-- <div style="text-align: left">
              <span class="mb-0 fs-14 font-bold">Mẫu số:</span>
              <span></span>
            </div>
            <div style="text-align: left" v-if="form.person.code">
              <span class="mb-0 fs-14 font-bold">PID:</span>
              <span v-if="form.person.code" class="mb-0 fs-14">{{ form.person.code }}</span>
            </div>
            <div style="text-align: left">
              <span class="mb-0 fs-14 font-bold">Số hồ sơ:</span>
              <span></span>
            </div>
            <div style="text-align: left">
              <span class="mb-0 fs-14 font-bold">Số phiếu:</span>
              <span></span>
            </div>-->
            <p class="mb-0 fs-18 font-bold uppercase" v-if="clinic.name">
              {{ clinic.name }}
            </p>
            <p class="mb-0 text-xl" v-if="clinic.address">
              {{ clinic.address }}
            </p>
            <p class="mb-0 text-xl" v-if="clinic.phone">{{ clinic.phone }}</p>
          </div>
        </div>
      </div>
      <div class="mt-4 mb-8">
        <p class="fs-24 text-center uppercase font-bold mb-2">
          {{ title }}
          <!-- <p class="text-center fs-16" v-if="code">{{ code }}</p> -->
        </p>
      </div>
      <div>
        <div class="pl-12 pr-12">
          <div class="mb-2" v-if="isShowPerson">
            <div class="block mb-2">
              <div class="inline-block w-full row">
                <div class="w-two-thirds inline-block">
                  <span class="font-bold fs-18 mr-1"
                    >{{ $t("Họ và tên") }}:</span
                  >
                  <span class="fs-18">{{ form.person.name }}</span>
                </div>
                <div
                  v-if="
                    form.prescription && form.prescription.prescription_code
                  "
                  class="w-one-thirds inline-block"
                >
                  <span class="font-bold fs-18 mr-1"
                    >{{ $t("Mã đơn thuốc") }}:</span
                  >
                  <span class="fs-18">{{
                    form.prescription.prescription_code
                  }}</span>
                </div>
              </div>
            </div>
            <div class="block mb-2">
              <!-- <div class="w-one-third inline-block">
                <div>
                  <span class="font-bold fs-18 mr-1">{{ $t('Họ và tên') }}:</span>
                  <span class="fs-18">{{ form.person.name }}</span>
                </div>
              </div>-->
              <div class="inline-block w-full row">
                <div class="w-two-thirds inline-block">
                  <div>
                    <span class="font-bold fs-18 mr-1"
                      >{{ $t("Ngày sinh") }}:</span
                    >
                    <span class="fs-18" v-if="form.person.birthday">
                      {{
                        `${appUtils.formatDate(
                          form.person.birthday
                        )} (${mdtUtils.getAges(
                          new Date(form.person.birthday)
                        )})`
                      }}
                    </span>
                  </div>
                </div>
                <div class="w-one-third inline-block">
                  <div>
                    <span class="font-bold fs-18 mr-1"
                      >{{ $t("Giới tính") }}:</span
                    >
                    <span class="fs-18" v-if="form.person.gender">{{
                      mdtUtils.getGender(form.person.gender, $lang)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="block mb-2">
              <div class="w-two-thirds inline-block">
                <div>
                  <span class="font-bold fs-18 mr-1">{{ $t("Địa chỉ") }}:</span>
                  <span class="fs-18" v-if="form.person.address">{{
                    form.person.address
                  }}</span>
                </div>
              </div>
              <div class="w-one-third inline-block">
                <div>
                  <span class="font-bold fs-18 mr-1"
                    >{{ $t("Số điện thoại") }}:</span
                  >
                  <span class="fs-18" v-if="form.person.phone">{{
                    form.person.phone
                  }}</span>
                </div>
              </div>
            </div>
          </div>

          <slot></slot>
        </div>
        <div class="block w-full">
          <div
            class="w-half inline-block align-top"
            v-if="$globalClinic.org_id === 12"
          >
            <div
              class="text-left mb-2 fs-18 pl-12 italic"
              style="font-weight: 700"
            >
              Giờ khám bệnh:
            </div>
            <div class="text-left mb-2 fs-18 pl-12">
              Chiều 17:30 - 20h từ T2-T6 hàng tuần
            </div>
            <div class="text-left mb-2 fs-18 pl-12">
              Thứ 7 sáng từ 9h đến 12h
            </div>
          </div>
          <div class="w-half inline-block align-top" v-else></div>

          <div class="w-half inline-block align-top" v-if="form.sign">
            <div class="text-center mb-2 fs-18" v-if="form.sign.sign_at">
              {{ getHMDMYVN(form.sign.sign_at) }}
            </div>
            <div class="font-bold text-center uppercase fs-18">
              {{ $t("Bác sĩ chỉ định") }}
            </div>
            <!-- <div class="text-center italic">{{ $t("(Đã xem)") }}</div> -->
            <div
              class="text-center mt-12 mb-3 fs-18"
              v-if="form.sign.signer_name"
            >
              {{ form.sign.signer_name }}
            </div>
            <div class="text-center" v-if="form.sign.isDocSign">
              <div class="inline-block">
                <div class="text-center" style="line-height: 1; font-size: 0">
                  <span
                    class="block fs-18"
                    style="
                      background-color: #c0c0c0;
                      color: black;
                      padding: 2px 4px;
                      font-size: 10px;
                      border-radius: 4px 4px 0 0;
                    "
                    >{{ $t("Chữ ký số") }}</span
                  >
                </div>
                <div
                  class="inline-block"
                  style="
                    border: 1px solid #c0c0c0;
                    border-top-color: #a0a0a0 !important;
                    border-radius: 0 0 8px 8px;
                    padding: 0 8px 4px 8px;
                  "
                >
                  <div
                    class="text-center mb-1"
                    style="line-height: 1; font-size: 0"
                  >
                    <span
                      class="inline-block fs-18"
                      style="
                        background-color: #c0c0c0;
                        color: black;
                        padding: 2px 4px;
                        font-size: 10px;
                        border-radius: 0 0 4px 4px;
                      "
                    >
                      {{ appUtils.formatDateTimeFull(form.sign.sign_at) }}
                    </span>
                  </div>
                  <div
                    class="text-center fs-18"
                    style="
                      font-size: 16px;
                      font-style: italic;
                      font-weight: bold;
                    "
                    v-if="form.sign.signer_name"
                  >
                    {{ form.sign.signer_name }}
                  </div>
                  <div
                    class="text-center fs-18"
                    style="font-size: 11px"
                    v-if="form.sign.uuid"
                  >
                    {{ form.sign.uuid }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="block w-full text-center align-bottom"
        v-if="$globalClinic.org_id === 12"
      >
        <span class="font-bold">* Lưu ý:</span>
        <span class="ml-1 italic">Mang theo đơn cũ khi tái khám</span>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../utils/appUtils'
import mdtUtils from '../utils/mdtUtils'
import { mixinPrint } from '../utils/mixinPrint'

export default {
  name: 'BaseTemp',
  mixins: [mixinPrint],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    code: {
      type: String
    },
    formData: {
      type: Object,
      required: true
    },
    save_pdf: {
      type: Number,
      required: true
    },
    checkLang: {
      type: Number
    },
    isShowPerson: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      clinic: null,
      loading: false,
      saveLang: this.checkLang || 1,
      form: null,
      appUtils,
      mdtUtils
    }
  },
  mounted () {
    this.form = this.formData
    this.clinic = this.$globalClinic || null
  },
  computed: {
    getLangCode () {
      return appUtils.getLangCode(this.saveLang)
    }
  },
  watch: {
    formData (data) {
      this.form = data
    },
    save_pdf (count) {
      console.log({ count })
      this.savePdfToS3()
    },
    print () {
      this.onPrintNe()
    }
  },
  methods: {
    async savePdfToS3 () {
      const self = this

      const bodyHTML = self.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      self.$root.$emit('onSavePDFToS3', base64Data)
    },
    getHMDMYVN (date) {
      const string = this.moment(date).locale(this.getLangCode)
      if (this.saveLang === 1) {
        const dt = this.moment(date)
        let hms = dt.clone().format('HH:mm:ss')
        const d = dt.clone().format('DD')
        const m = dt.clone().format('MM')
        const y = dt.clone().format('YYYY')
        return hms + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    onPrintNe () {
      const bodyHTML = this.$refs.documentPrint.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({
        bodyHTML,
        lang: this.getLangCode
      })

      this.onPrintTBBHC(tempHTML, true)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  body,
  html {
    font-family: "Times New Roman", Tinos, Times, serif !important;
  }

  p {
    margin-top: 0;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .top-0 {
    top: 0;
  }

  .left-0 {
    left: 0;
  }

  .block {
    display: block;
  }

  .inline-block {
    display: inline-block !important;
  }

  .flex {
    display: flex;
  }

  .flex-col {
    flex-direction: column;
  }

  .align-top {
    vertical-align: top;
  }

  .text-center {
    text-align: center;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-center {
    align-items: center;
  }

  .w-full {
    width: 100% !important;
  }

  .w-half {
    width: 50% !important;
  }

  .w-one-third {
    width: 33.333% !important;
  }

  .w-two-thirds {
    width: 66.667% !important;
  }

  .font-bold {
    font-weight: 700;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .pl-12 {
    padding-left: 3rem;
  }

  .pl-16 {
    padding-left: 4rem;
  }

  .pr-12 {
    padding-right: 3rem;
  }

  .pr-16 {
    padding-right: 4rem;
  }

  .mt-6 {
    margin-top: 1.5rem;
  }

  .mt-8 {
    margin-top: 2rem;
  }

  .mt-12 {
    margin-top: 3rem;
  }

  .mt-16 {
    margin-top: 4rem;
  }

  .mt-24 {
    margin-top: 6rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .mb-6 {
    margin-bottom: 1.5rem;
  }

  .mb-8 {
    margin-bottom: 2rem;
  }

  .hodo-table figure {
    margin: 0 0 1rem !important;
  }

  .hodo-table table {
    border-collapse: collapse !important;
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}
</style>
