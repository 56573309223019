<template>
  <div>
    <div class="p-3 mb-3">
      <!-- Page title -->
      <div class="card-header">
        <h2 class="title">
          Thiết lập kích thước lề văn bản
        </h2>
      </div>

      <div class="card-body">
        <form @submit.prevent="onSubmit" autocomplete="off" v-if="form">

          <div class="row">
            <div class="col-6">
              <div class="form-group mb-3">
                <label class="required form-label">Lề Trên:</label>
                <input type="number" class="form-control" v-model="form.padding.top">
              </div>
            </div>

            <div class="col-6">
              <div class="form-group mb-3">
                <label class="required form-label">Lề Dưới:</label>
                <input type="number" class="form-control" v-model="form.padding.bottom">
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-6">
              <div class="form-group mb-3">
                <label class="required form-label">Lề Trái:</label>
                <input type="number" class="form-control" v-model="form.padding.left">
              </div>
            </div>

            <div class="col-6">
              <div class="form-group mb-3">
                <label class="required form-label">Lề Phải:</label>
                <input type="number" class="form-control" v-model="form.padding.right">
              </div>
            </div>
          </div>

          <div class="form-group mb-3 text-center">
            <button type="submit" class="btn bg-pri bd-pri text-white">Cập Nhật</button>
          </div>

        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex'
  import TemplatePadding from '../../components/TemplatePadding'

  export default {
    name: 'DefaultAdminTemplate',
    components: {TemplatePadding},
    props: ['id'],
    computed: {
      ...mapState({
        form: state => state.basic.form
      })
    },
    methods: {
      async onSubmit () {
        let self = this

        let data = self.form

        self.$emit('onSubmit', data)
      },
      onRemove (name, idx) {
        let self = this

        var r = confirm('Bạn có muốn xóa mục này không?')
        if (!r) {
          return false
        }

        self.form[name].splice(idx, 1)
      },
      onAdd (name) {
        let self = this

        self.form[name].push({
          en_value: '',
          vi_value: ''
        })
      }
    }
  }
</script>

<style scoped>
  .check--box {
    margin-top: 25px;
    font-size: 18px;
  }

  .btn--remove {
    margin-top: 20px;
  }
</style>
