<template>
  <div class="container">
    <div :class="view ? '': 'd-none'">
      <div
        class="ptv"
        ref="documente"
      >
        <div class="row">
          <div class="col-12 row mb-3">
            <div
              class="col-6 d-flex"
              :set="(avatar = getAvatar())"
            >
              <img
                v-if="avatar"
                :src="avatar"
                alt=""
                width="70"
                height="70"
                class="mr-2"
              >
              <div v-if="clinic">
                <p class="mb-0 fz-10">
                  <span class="font-weight-bold tnr">{{ clinic.name }}</span>
                </p>
                <p class="mb-0 fz-10 tnr">Địa chỉ: {{ clinic.address }}</p>
                <p class="mb-0 fz-10 tnr">Số điện thoại: {{ clinic.phone }}</p>
                <p class="mb-0 fz-10 tnr">Email: {{ clinic.email }}</p>
              </div>
            </div>
            <div class="col-6">
              <p class="mb-0 text-center text-uppercase font-weight-bold fz-11">
                CỘNG HOÀ XÃ HỘI CHỦ NGHĨA VIỆT NAM
              </p>
              <p class="mb-0 text-center font-weight-bold fz-11">
                Độc lập - Tự do - Hạnh phúc
              </p>
              <p class="mb-3 text-center font-weight-bold fz-11">
                ------------------------------------
              </p>
            </div>
          </div>
          <div class="col-12">
            <p class="mb-3 text-center text-uppercase font-weight-bold fz-16">
              Phiếu tư vấn
            </p>
          </div>
          <div class="col-12">
            <p class="mb-3 text-right fz-11 font-italic">
              Thời gian bắt đầu:
              {{
                  getDateTime3(form && form.start_time)  || "..."
              }}
            </p>
            <div class="row mb-0">
              <div class="col-6">
                <p class="mb-2 fz-13">
                  Họ và tên:
                  <span class="font-weight-bold text-uppercase tnr">{{
                    !person ? (form && form.patient_name) || "..." : (person && person.name) || "..."
                  }}</span>
                </p>
              </div>
              <div class="col-6">
                <p class="mb-2 fz-13">
                  Số hồ sơ:
                  <span class="text-uppercase tnr">{{
                    (form && form.profile_number) || "..."
                  }}</span>
                </p>
              </div>
            </div>
            <p class="mb-2 fz-13">
              Giới tính: {{ !person ? (form && form.gender === 1 ? 'Nam' : 'Nữ') || "..............." : (person && person.gender === 1 ? 'Nam' : 'Nữ') || "..............." }}, Ngày
              sinh: {{ !person ? (form && form.birthday) || ".../.../......" : (person && person.birthday) || ".../.../......"}}, Nghề
              nghiệp: {{ !person ? (form && form.career) || "..........." : (person && person.career) || "..........." }}
            </p>
            <p class="mb-2 fz-13">
              Địa chỉ:
              {{
                !person ?
                (form && form.address) ||
                "................................................................" :
                (person && person.address) ||
                "................................................................"
              }}
            </p>
            <p class="mb-2 fz-13">
              Số điện thoại: {{ !person ? (form && form.phone) || ".................." : (person && person.phone) || ".................." }}
            </p>
            <p class="mb-3 fz-13">
              Người liên hệ:
              <span class="font-weight-bold text-uppercase tnr">{{
                (form && form.contact_name) || "....................."
              }}</span>, Mối quan hệ:
              {{ (form && form.contact_relationship) || ".............." }}, Số
              điện thoại:
              {{ (form && form.contact_phone) || "..............." }}
            </p>
            <p class="mb-2 font-weight-bold">Lý do khám bệnh:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.reason }}</p>
            <div
              v-if="!form || !form.reason"
              class="wordbreak mb-2 ml-4"
            >
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Diễn biến:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.progression }}</p>
            <div
              v-if="!form || !form.progression"
              class="wordbreak mb-2 ml-4"
            >
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Kết quả xét nghiệm:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.prehistoric }}</p>
            <div
              v-if="!form || !form.prehistoric"
              class="wordbreak mb-2 ml-4"
            >
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Chẩn đoán:</p>
            <p class="mb-2 pre-wrap ml-4">{{ form && form.diagnose }}</p>
            <div
              v-if="!form || !form.diagnose"
              class="wordbreak mb-2 ml-4"
            >
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
            <p class="mb-2 font-weight-bold">Chỉ định:</p>
            <p
              class="mb-2 pre-wrap ml-4"
              v-if="form && form.indication"
            >
              {{ form && form.indication }}
            </p>
            <div
              v-if="!form || !form.indication"
              class="wordbreak mb-2 ml-4"
            >
              ................................................................................................................................................................................................................................................................................................................................................................................
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="text-center">
              <p class="mb-2 fz-13">
                {{
                    getHMDMYVN(form && form.end_time) || ".........."
                }}
              </p>
              <p class="mb-5 fz-13">Bác sĩ tư vấn</p>
              <p class="mb-5 fz-13">
                {{ form && form.doctor && form.doctor.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-wrap justify-content-end"
      v-if="form && !view"
    >
      <button
        class="btn bg-pri bd-pri text-white mt-3 mb-2 fs-14 fw-600 rounded-xl"
        style="width: 120px; height: 46px"
        @click="exp()"
        :disabled="loading"
      >
        <div
          class="p-center d-flex justify-content-center align-items-center"
          v-if="loading"
        >
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
          ></span>
        </div>
        <span v-else>Lưu</span>
      </button>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
export default {
  name: 'PhieuTuVan',
  props: ['form', 'update_id', 'is_create', 'is_update', 'person', 'view'],
  data () {
    return {
      clinic: null,
      loading: false
    }
  },
  mounted () {
    this.clinic = this.$globalClinic
  },
  methods: {
    exp () {
      this.loading = true
      setTimeout(() => {
        this.exportPdf()
      }, 50)
    },
    exportPdf () {
      let self = this
      let options = {
        margin: 14,
        filename: `phieutuvan_${window.moment().unix()}.pdf`,
        image: { type: 'jpeg', quality: 2 },
        html2canvas: {
          dpi: 500,
          scale: 4,
          letterRendering: true,
          allowTaint: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      html2pdf().from(this.$refs.documente).set(options).toPdf().outputPdf().then((pdf) => {
        let newpdf = btoa(pdf)
        let file = this.dataURLtoFile(
          'data:application/octet-stream;base64,' + newpdf,
          `phieutuvan_${window.moment().unix()}.pdf`
        )
        self.savePhieuTuVan(file, self.form)
        this.loading = false
      }).save()
    },
    getAvatar () {
      let clinic = this.$globalClinic
      if (clinic && clinic.avatar) {
        return process.env.VUE_APP_BASE_IMAGE_URL + 'api/logo/' + clinic.avatar
      }
    },
    async savePhieuTuVan (file, form) {
      let self = this
      let data = form
      let fileSave
      data.start_time = self.getDateTime2(data.start_time)
      if (file) data.pdf_file = file
      let formData = new FormData()
      for (var key in data) {
        if (data[key]) formData.append(key, data[key])
      }
      if (self.update_id) {
        await self.$rf
          .getRequest('DoctorRequest')
          .updateConsultation(self.update_id, formData)
          .then((res) => {
            fileSave = res?.data
            self.showSaveSuccess()
            self.$router.push({
              path: `/doctor/consultation/${res?.data?.id}`
            })
          })
      } else {
        await self.$rf
          .getRequest('DoctorRequest')
          .createConsultation(formData)
          .then((res) => {
            fileSave = res?.data
            self.showSaveSuccess()
            self.$router.push({
              path: `/doctor/consultation/${res?.data?.id}`
            })
          })
      }
      this.$emit('saveFile', fileSave)
    },
    async savePhieuTuVan2 () {
      let self = this
      let params = {
        person_id: self.form.person_id || '',
        reason: self.form.reason || '',
        progression: self.form.progression || '',
        prehistoric: self.form.prehistoric || '',
        diagnose: self.form.diagnose || '',
        indication: self.form.indication || '',
        appt_id: self.form.appt_id || '',
        person_diseases_id: self.form.person_diseases_id,
        start_time: self.getDateTime2(self.form.start_time)
      }
      if (self.form.notes) params.notes = self.form.notes
      if (self.update_id) {
        await self.$rf
          .getRequest('DoctorRequest')
          .updateConsultation(self.update_id, params)
          .then((res) => {
            self.showSaveSuccess()
            self.$router.push({
              path: `/doctor/consultation/${res?.data?.id}`
            })
          })
      } else {
        await self.$rf
          .getRequest('DoctorRequest')
          .createConsultation(params)
          .then((res) => {
            if (res?.data) {
              self.showSaveSuccess()
              self.$router.push({
                path: `/doctor/consultation/${res?.data?.id}`
              })
            }
          })
      }
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    showSaveSuccess () {
      this.$toast.open({
        message: 'Lưu thành công',
        type: 'success'
      })
    },
    getDate (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY')
    },
    getDateTime (date_time) {
      return window.moment(date_time).format('HH:mm DD/MM/YYYY')
    },
    getDateTime2 (date_time) {
      return window.moment(date_time).format('YYYY-MM-DD HH:mm')
    },
    getDateTime3 (date_time) {
      return window.moment(date_time).format('DD/MM/YYYY HH:mm')
    },
    getHMDMYVN (date_time) {
      let dt = window.moment(date_time)
      // let hm = dt.clone().format('HH:mm')
      let d = dt.clone().format('DD')
      let m = dt.clone().format('MM')
      let y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    }
  }
}
</script>
<style lang="css" scoped>
@font-face {
  font-family: Time;
  src: url(../../../public/assets/font/tnr.ttf);
}

p {
  font-family: Time;
  color: black;
}

.fz-13 {
  font-size: 16px;
  font-family: Time;
}

.fz-11 {
  font-size: 14px;
  font-family: Time;
}

.wordbreak {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.pre-wrap {
  white-space: pre-wrap;
}
.text-red {
  color: #e70000;
}
.fz-10 {
  font-size: 13px;
  font-family: Time;
}
.fz-16 {
  font-size: 19px;
  font-family: Time;
}
.c-info {
  width: 25%;
}
.midle {
  width: 75%;
  text-align: center;
  margin-left: -12.5%;
}
.tnr {
  font-family: Time;
}
</style>
