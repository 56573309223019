import Vue from 'vue'

const person = {
  namespaced: true,
  state: {
    emrVersions: [],
    isFetching: false,
    pagination: {}
  },
  getters: {},
  mutations: {
    setMdtRoomDetail (state, data) {
      state.emrVersions = data
    }
  },
  actions: {
    async getEMRVersion ({ commit, state }, params) {
      try {
        state.isFetching = true

        const response = await Vue.prototype.$rf
          .getRequest('DoctorRequest')
          .getEMRVersion(params)

        state.emrVersions = response.data?.data || []
        state.pagination = response.data?.page || {}
      } catch (error) {
        console.log(error)
      } finally {
        state.isFetching = false
      }
    }
  }
}

export default person
